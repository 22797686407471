import {Icon, IconProps} from '@chakra-ui/react';

export function ChatIcon(props: IconProps) {
  return (
    <Icon width="17px" height="16px" viewBox="0 0 17 16" fill="#FFEC45" {...props}>
      <path
        d="M1.83337 8C1.83337 11.3333 4.50004 14 7.83337 14C9.23337 14 10.5667 13.4667 11.5667 12.6667H14.2992C14.3946 12.6667 14.4591 12.5695 14.4221 12.4816L13.4334 10.1333C13.7 9.46667 13.8334 8.73333 13.8334 8C13.8334 4.66667 11.1667 2 7.83337 2C4.50004 2 1.83337 4.66667 1.83337 8Z"
        fill="inherit"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M5.16671 8.66667C5.3508 8.66667 5.50004 8.51743 5.50004 8.33333C5.50004 8.14924 5.3508 8 5.16671 8C4.98261 8 4.83337 8.14924 4.83337 8.33333C4.83337 8.51743 4.98261 8.66667 5.16671 8.66667Z"
        fill="currentColor"
        stroke="currentColor"
      />

      <path
        d="M7.83333 8.66667C8.01743 8.66667 8.16667 8.51743 8.16667 8.33333C8.16667 8.14924 8.01743 8 7.83333 8C7.64924 8 7.5 8.14924 7.5 8.33333C7.5 8.51743 7.64924 8.66667 7.83333 8.66667Z"
        fill="currentColor"
        stroke="currentColor"
      />

      <path
        d="M10.5 8.66667C10.6841 8.66667 10.8333 8.51743 10.8333 8.33333C10.8333 8.14924 10.6841 8 10.5 8C10.3159 8 10.1666 8.14924 10.1666 8.33333C10.1666 8.51743 10.3159 8.66667 10.5 8.66667Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </Icon>
  );
}
