import {Icon, IconProps} from '@chakra-ui/react';

export function NoIcon(props: IconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
      <rect width="16" height="16" rx="8" fill="#CB2525" />
      <path
        d="M10.6666 5.3335L5.33331 10.6668M10.6666 10.6668L5.33331 5.3335"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
