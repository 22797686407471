import {Icon, IconProps} from '@chakra-ui/react';

export function LinkIcon({fillColor = '#ABABAB', ...props}: IconProps & {fillColor?: string}) {
  return (
    <Icon width="35px" height="35px" viewBox="0 0 35 35" fill="none" {...props}>
      <path
        d="M17.5 35C27.1495 35 35 27.1495 35 17.5C35 7.85054 27.1495 0 17.5 0C7.85054 0 0 7.85054 0 17.5C0 27.1495 7.85054 35 17.5 35Z"
        fill={fillColor}
      />
      <path
        d="M24.7414 16.2921L22.0339 18.9987C21.8549 19.1776 21.5864 19.1776 21.4074 18.9987C21.2284 18.8197 21.2284 18.5513 21.4074 18.3724L24.1148 15.6658C25.435 14.3461 25.435 12.1987 24.1148 10.8789C23.4659 10.2303 22.638 9.89474 21.7206 9.89474C20.8256 9.89474 19.9753 10.2526 19.3264 10.8789L16.619 13.5855C15.2988 14.9053 15.2988 17.0526 16.619 18.3724C16.9322 18.6855 17.3126 18.9539 17.7377 19.1105C17.9615 19.2 18.0734 19.4684 17.9839 19.6921C17.7825 20.1618 17.2679 19.8934 17.4021 19.9382C16.8651 19.7145 16.3952 19.4013 15.9924 18.9987C14.3142 17.3434 14.3142 14.6145 15.9924 12.9592L18.6999 10.2526C19.5054 9.44737 20.5795 9 21.7206 9C22.8618 9 23.9358 9.44737 24.7414 10.2526C26.4195 11.9079 26.4195 14.6368 24.7414 16.2921ZM17.5811 15.0618C17.3574 14.9724 17.0888 15.0842 16.9993 15.3079C16.9098 15.5316 17.0217 15.8 17.2455 15.8895C17.6706 16.0684 18.051 16.3145 18.3643 16.6276C19.6844 17.9474 19.6844 20.0947 18.3643 21.4145L15.6568 24.1211C15.0079 24.7697 14.18 25.1053 13.2626 25.1053C12.3676 25.1053 11.5173 24.7474 10.8684 24.1211C9.54821 22.8013 9.54821 20.6539 10.8684 19.3342L13.5758 16.6276C13.7549 16.4487 13.7549 16.1803 13.5758 16.0013C13.3968 15.8224 13.1283 15.8224 12.9493 16.0013L10.2419 18.7079C8.58605 20.3632 8.58605 23.0921 10.2419 24.7474C11.0474 25.5526 12.1214 26 13.2626 26C14.4038 26 15.4778 25.5526 16.2833 24.7474L18.9908 22.0408C20.669 20.3855 20.669 17.6566 18.9908 16.0013C18.588 15.5987 18.1181 15.2855 17.5811 15.0618V15.0618Z"
        fill="white"
      />
    </Icon>
  );
}
