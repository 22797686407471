import {Icon, IconProps} from '@chakra-ui/react';

export function EditIcon(props: IconProps) {
  return (
    <Icon width="50px" height="50px" viewBox="0 0 50 50" fill="white" {...props}>
      <circle cx="25" cy="25" r="25" fill="inherit" />
      <path
        d="M19.3005 33.7308L32.1734 20.8552L34.4436 18.5771C34.7999 18.2201 35 17.7363 35 17.2318C35 16.7273 34.7999 16.2434 34.4436 15.8865L32.4334 13.8749C32.072 13.5274 31.5903 13.3333 31.0891 13.3333C30.5879 13.3333 30.1061 13.5274 29.7447 13.8749L27.4682 16.1467L13.5172 30.1074C13.4585 30.1667 13.412 30.237 13.3804 30.3143C13.3489 30.3916 13.3329 30.4744 13.3333 30.5579V34.3654C13.3333 34.5337 13.4002 34.6951 13.5191 34.8141C13.638 34.9331 13.7993 35 13.9675 35L17.7723 35C17.7723 35 17.9778 34.9903 18.0893 34.9365C18.1797 34.893 18.2667 34.8141 18.2667 34.8141L19.3005 33.7308ZM30.6388 14.7696C30.6978 14.7101 30.7679 14.6629 30.8452 14.6307C30.9225 14.5985 31.0054 14.5819 31.0891 14.5819C31.1728 14.5819 31.2557 14.5985 31.3329 14.6307C31.4102 14.6629 31.4804 14.7101 31.5393 14.7696L33.5495 16.7812C33.609 16.8402 33.6561 16.9104 33.6883 16.9877C33.7205 17.0651 33.7371 17.148 33.7371 17.2318C33.7371 17.3156 33.7205 17.3985 33.6883 17.4758C33.6561 17.5532 33.609 17.6233 33.5495 17.6823L31.7232 19.5099L28.8125 16.5972L30.6388 14.7696ZM14.6016 33.7308V30.8181L27.9184 17.492L30.8291 20.4047L17.5123 33.7308H14.6016Z"
        fill="#101010"
        stroke="#101010"
        strokeWidth="0.25"
      />
    </Icon>
  );
}
