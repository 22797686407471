import {Icon, IconProps} from '@chakra-ui/react';

export function RocketIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.6625 20.6631C10.0875 19.1631 10.05 16.8006 8.58749 15.3756C7.125 13.9881 4.8375 13.9881 3.375 15.3756C1.875 16.8006 0 24.0006 0 24.0006C0 24.0006 7.2 22.1256 8.6625 20.6631Z"
        fill="url(#paint0_linear_11_1060)"
      />
      <path
        d="M13.8753 16.875L13.2753 17.475C12.9753 17.775 12.5253 17.775 12.2253 17.475L6.52529 11.775C6.22529 11.475 6.22529 11.025 6.52529 10.725L7.12529 10.125L13.8753 16.875Z"
        fill="url(#paint1_linear_11_1060)"
      />
      <path
        d="M24 0C24 4.3125 22.275 8.475 19.2375 11.5125L13.875 16.875L7.125 10.125L12.45 4.7625C15.525 1.725 19.6875 0 24 0Z"
        fill="url(#paint2_linear_11_1060)"
      />
      <path
        d="M8.625 17.9994C9.2625 17.1744 9.075 16.0119 8.25 15.3744C7.575 14.8869 6.675 14.8869 6 15.3744C5.25 16.1244 4.125 19.8744 4.125 19.8744C4.125 19.8744 7.9125 18.7494 8.625 17.9994Z"
        fill="url(#paint3_linear_11_1060)"
      />
      <path
        d="M11.9998 5.25H11.3998C8.17485 5.25 5.09985 6.525 2.81235 8.8125C2.54985 9.075 2.54985 9.4875 2.81235 9.7125C2.96235 9.8625 3.22485 9.9375 3.44985 9.8625C4.64985 9.525 5.96235 9.6 7.12485 10.0875L11.9998 5.25Z"
        fill="url(#paint4_linear_11_1060)"
      />
      <path
        d="M18.7499 12V12.6C18.7499 15.825 17.4749 18.9 15.1874 21.1875C14.9249 21.45 14.5124 21.45 14.2874 21.1875C14.1374 21.0375 14.0624 20.775 14.1374 20.55C14.4749 19.35 14.3999 18.0375 13.9124 16.875L18.7499 12Z"
        fill="url(#paint5_linear_11_1060)"
      />
      <path
        d="M23.9999 0C23.9999 1.5 23.7749 3 23.3624 4.4625C21.3749 4.1625 19.8374 2.625 19.5374 0.6375C20.9999 0.225 22.4999 0 23.9999 0Z"
        fill="url(#paint6_linear_11_1060)"
      />
      <path
        d="M17.625 8.625C18.8676 8.625 19.875 7.61764 19.875 6.375C19.875 5.13236 18.8676 4.125 17.625 4.125C16.3824 4.125 15.375 5.13236 15.375 6.375C15.375 7.61764 16.3824 8.625 17.625 8.625Z"
        fill="url(#paint7_linear_11_1060)"
      />
      <path
        d="M17.625 9C16.1625 9 15 7.8375 15 6.375C15 4.9125 16.1625 3.75 17.625 3.75C19.0875 3.75 20.25 4.9125 20.25 6.375C20.25 7.8375 19.0875 9 17.625 9ZM17.625 4.5C16.575 4.5 15.75 5.325 15.75 6.375C15.75 7.425 16.575 8.25 17.625 8.25C18.675 8.25 19.5 7.425 19.5 6.375C19.5 5.325 18.675 4.5 17.625 4.5Z"
        fill="url(#paint8_linear_11_1060)"
      />
      <path
        d="M8.625 16.125C8.4375 16.125 8.25 16.05 8.1 15.9C7.8 15.6 7.8 15.15 8.1 14.85L13.35 9.6C13.65 9.3 14.1 9.3 14.4 9.6C14.7 9.9 14.7 10.35 14.4 10.65L9.15 15.9C9 16.05 8.8125 16.125 8.625 16.125Z"
        fill="url(#paint9_linear_11_1060)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11_1060"
          x1="0"
          y1="19.1485"
          x2="9.74999"
          y2="19.1485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9A254" />
          <stop offset="1" stopColor="#F4BB79" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_1060"
          x1="6.31079"
          y1="13.9166"
          x2="13.894"
          y2="13.9166"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#474F72" />
          <stop offset="1" stopColor="#878C9F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_1060"
          x1="15.5719"
          y1="16.8562"
          x2="15.5719"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4D8E1" />
          <stop offset="1" stopColor="#F3F4F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_1060"
          x1="4.125"
          y1="17.4369"
          x2="9.1875"
          y2="17.4369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC88" />
          <stop offset="1" stopColor="#FFE5C3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11_1060"
          x1="2.63085"
          y1="7.6875"
          x2="11.9998"
          y2="7.6875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA5466" />
          <stop offset="1" stopColor="#F4929C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11_1060"
          x1="16.3124"
          y1="21.369"
          x2="16.3124"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA5466" />
          <stop offset="1" stopColor="#F4929C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11_1060"
          x1="21.7724"
          y1="4.455"
          x2="21.7724"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA5466" />
          <stop offset="1" stopColor="#F4929C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11_1060"
          x1="15.375"
          y1="6.375"
          x2="19.875"
          y2="6.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DD5DF" />
          <stop offset="1" stopColor="#C3E1F5" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11_1060"
          x1="17.625"
          y1="9"
          x2="17.625"
          y2="3.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#949AAF" />
          <stop offset="1" stopColor="#C6CCDA" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_11_1060"
          x1="11.25"
          y1="16.125"
          x2="11.25"
          y2="9.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA5466" />
          <stop offset="1" stopColor="#F4929C" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
