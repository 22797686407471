import {Icon, IconProps} from '@chakra-ui/react';

export function GroupDescIcon(props: IconProps) {
  return (
    <Icon width="17px" height="17px" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M4.68196 16.0042L14.7822 5.90179L16.5635 4.11433C16.843 3.83426 17 3.45461 17 3.05878C17 2.66295 16.843 2.28331 16.5635 2.00324L14.9862 0.424894C14.7027 0.152261 14.3247 0 13.9314 0C13.5382 0 13.1602 0.152261 12.8766 0.424894L11.0904 2.20737L0.144298 13.1612C0.0981843 13.2077 0.0617013 13.2629 0.0369409 13.3235C0.0121805 13.3842 -0.000370267 13.4491 8.31621e-06 13.5147V16.5021C8.31621e-06 16.6341 0.0524287 16.7608 0.145738 16.8541C0.239046 16.9475 0.3656 17 0.497559 17L3.48285 17C3.48285 17 3.64411 16.9924 3.73163 16.9502C3.80252 16.916 3.8708 16.8541 3.8708 16.8541L4.68196 16.0042ZM13.5782 1.12693C13.6244 1.08027 13.6795 1.04322 13.7401 1.01795C13.8007 0.992669 13.8657 0.979655 13.9314 0.979655C13.9971 0.979655 14.0621 0.992669 14.1228 1.01795C14.1834 1.04322 14.2384 1.08027 14.2847 1.12693L15.8619 2.70527C15.9086 2.75156 15.9456 2.80663 15.9708 2.8673C15.9961 2.92798 16.0091 2.99305 16.0091 3.05878C16.0091 3.12451 15.9961 3.18959 15.9708 3.25026C15.9456 3.31094 15.9086 3.36601 15.8619 3.41229L14.429 4.84624L12.1452 2.56088L13.5782 1.12693ZM0.99511 16.0042V13.7188L11.4437 3.26292L13.7274 5.54828L3.27887 16.0042H0.99511Z"
        fill="#101010"
      />
    </Icon>
  );
}
