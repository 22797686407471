import {Icon, IconProps} from '@chakra-ui/react';

export function SuccessIcon(props: IconProps) {
  return (
    <Icon width="49px" height="48px" viewBox="0 0 49 48" fill="none" {...props}>
      <circle cx="24.5" cy="24" r="24" fill="#5AC57B" fillOpacity="0.2" />
      <rect x="6" y="6" width="36" height="36" rx="18" fill="#5AC57B" />
      <path
        d="M15.5 24.4431L20.6515 30L32.5 18"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
