import {Icon, IconProps} from '@chakra-ui/react';

export const FilterIcon = (props: IconProps) => (
  <Icon width="14px" height="13px" viewBox="0 0 14 13" fill="none" color="#2a2e3a" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00249 12.5C5.6615 12.5 5.65582 11.1625 5.65582 8.89966C5.65582 6.63682 1 5.05145 1 2.56721C1 0.468691 2.8602 0.499996 6.99964 0.499996C11.1398 0.499996 13 0.468691 13 2.56721C13 5.05145 8.3449 6.63682 8.3449 8.89966C8.3449 11.1625 8.34276 12.5 7.00249 12.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
