import {Icon, IconProps} from '@chakra-ui/react';

export function ArrowDownCircleIcon(props: IconProps) {
  return (
    <Icon width="21px" height="20px" viewBox="0 0 21 20" fill="none" color="white" {...props}>
      <path
        d="M10.5 0C16.01 0 20.5 4.48 20.5 10C20.5 15.51 16.01 20 10.5 20C4.98 20 0.5 15.51 0.5 10C0.5 4.48 4.98 0 10.5 0ZM7.56 8.03C7.27 7.73 6.79 7.73 6.5 8.02C6.2 8.32 6.2 8.79 6.5 9.08L9.97 12.57C10.11 12.71 10.3 12.79 10.5 12.79C10.7 12.79 10.89 12.71 11.03 12.57L14.5 9.08C14.65 8.94 14.72 8.75 14.72 8.56C14.72 8.36 14.65 8.17 14.5 8.02C14.2 7.73 13.73 7.73 13.44 8.03L10.5 10.98L7.56 8.03Z"
        fill="currentColor"
      />
    </Icon>
  );
}
