import {Icon, IconProps} from '@chakra-ui/react';

export function CoinsIcon3(props: IconProps) {
  return (
    <Icon width="19px" height="19px" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M2.74023 10.5137V14.0162C2.74023 15.7674 5.67483 17.5187 10.3702 17.5187C15.0655 17.5187 18.0001 15.7674 18.0001 14.0162V10.5137"
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.74023 10.5121C2.74023 12.4465 6.15628 14.0147 10.3702 14.0147C14.5841 14.0147 18.0001 12.4465 18.0001 10.5121C18.0001 9.72694 17.4373 9.00209 16.4867 8.41797"
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.3691 14.0156V17.518" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.0664 13.293V16.7955" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.67578 13.293V16.7955" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1 4.74609V8.24859C1 9.99983 3.9346 11.7511 8.62996 11.7511C13.3253 11.7511 16.2599 9.99983 16.2599 8.24859V4.74609"
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.62996 8.24908C12.8439 8.24908 16.2599 6.68097 16.2599 4.74661C16.2599 2.81225 12.8439 1.24414 8.62996 1.24414C4.41605 1.24414 1 2.81225 1 4.74661C1 6.68097 4.41605 8.24908 8.62996 8.24908Z"
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.62891 8.24805V11.7506" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3262 7.5293V11.0318" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.93555 7.5293V11.0318" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
