import {Icon, IconProps} from '@chakra-ui/react';

export function ArrowBackwardIcon(props: IconProps) {
  return (
    <Icon width="7px" height="12px" viewBox="0 0 7 12" fill="none" {...props}>
      <path
        d="M6.03125 11L0.999085 6L6.03125 1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
