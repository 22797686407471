import {Icon, IconProps} from '@chakra-ui/react';

export function DeleteIcon({opacity, ...props}: IconProps) {
  return (
    <Icon width="20px" height="24px" viewBox="0 0 20 24" fill="currentColor" opacity="0.4" {...props}>
      <g>
        <path d="M6.41895 7.46484H7.92207V19.4899H6.41895V7.46484Z" fill="inherit" />
        <path d="M9.42578 7.46484H10.9289V19.4899H9.42578V7.46484Z" fill="inherit" />
        <path d="M12.4326 7.46484H13.9357V19.4899H12.4326V7.46484Z" fill="inherit" />
        <path d="M0.407227 2.95703H19.9479V4.46016H0.407227V2.95703Z" fill="inherit" />
        <path
          d="M13.8852 3.70772H12.4822V2.20459C12.4822 1.75365 12.1315 1.40292 11.6806 1.40292H8.67431C8.22337 1.40292 7.87264 1.75365 7.87264 2.20459V3.70772H6.46973V2.20459C6.46973 1.00208 7.47181 0 8.67431 0H11.6806C12.8831 0 13.8852 1.00208 13.8852 2.20459V3.70772Z"
          fill="inherit"
        />
        <path
          d="M14.6877 23.9986H5.66896C4.46645 23.9986 3.41426 22.9965 3.31406 21.794L1.91113 3.75646L3.41426 3.65625L4.81718 21.6938C4.86729 22.1447 5.26812 22.4955 5.66896 22.4955H14.6877C15.1387 22.4955 15.5395 22.0946 15.5395 21.6938L16.9424 3.65625L18.4456 3.75646L17.0426 21.794C16.9424 23.0466 15.8902 23.9986 14.6877 23.9986Z"
          fill="inherit"
        />
      </g>
    </Icon>
  );
}
