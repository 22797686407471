import {Icon, IconProps} from '@chakra-ui/react';

export const InfoIcon = (props: IconProps) => (
  <Icon width="13ox" height="13px" viewBox="0 0 13 13" fill="none" color="#2A2E3A" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.896 6.49998C11.896 10.5466 10.5467 11.8958 6.50016 11.8958C2.45358 11.8958 1.10433 10.5466 1.10433 6.49998C1.10433 2.4534 2.45358 1.10415 6.50016 1.10415C10.5467 1.10415 11.896 2.4534 11.896 6.49998Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.5 8.77209L6.5 6.50001" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.50269 4.45837H6.49669" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
