import {Icon, IconProps} from '@chakra-ui/react';

export const PersonIcon = ({color = '#5AC57B', ...rest}: IconProps) => (
  <Icon width="46px" height="44px" viewBox="0 0 46 44" color={color} {...rest}>
    <path
      d="M20.8669 16.8742C19.188 16.8742 17.5467 16.3794 16.1507 15.4523C14.7547 14.5252 13.6666 13.2075 13.0241 11.6658C12.3816 10.1242 12.2135 8.42775 12.541 6.79111C12.8686 5.15447 13.6771 3.65112 14.8643 2.47117C16.0515 1.29122 17.5641 0.487668 19.2108 0.16212C20.8575 -0.163427 22.5644 0.00365564 24.1155 0.64224C25.6667 1.28082 26.9925 2.36223 27.9253 3.7497C28.8581 5.13718 29.3559 6.76841 29.3559 8.43711C29.3559 10.6748 28.4615 12.8208 26.8696 14.403C25.2776 15.9853 23.1184 16.8742 20.8669 16.8742Z"
      fill="#5AC57B"
    />
    <path
      d="M21.9281 41.1308H41.0283V39.0215C41.0283 33.7071 38.9042 28.6103 35.1232 24.8524C31.3422 21.0946 26.2141 18.9834 20.8669 18.9834C15.5198 18.9834 10.3917 21.0946 6.61069 24.8524C2.8297 28.6103 0.705566 33.7071 0.705566 39.0215V41.1308H21.9281Z"
      fill="#5AC57B"
    />
    <path
      d="M7.07244 41.6309H25.6727V42.7401H5.4502V41.1309C5.4502 35.95 7.52091 30.9807 11.2078 27.3164C14.8948 23.6519 19.8961 21.5927 25.1116 21.5927C30.3271 21.5927 35.3284 23.6519 39.0153 27.3164C42.7022 30.9807 44.7729 35.95 44.7729 41.1309V42.7401H26.6727V41.6309H43.1507H43.6507V41.1309C43.6507 36.2422 41.6967 31.5544 38.2196 28.0986C34.7426 24.6429 30.0275 22.702 25.1116 22.702C20.1957 22.702 15.4805 24.6429 12.0035 28.0986C8.52642 31.5544 6.57244 36.2422 6.57244 41.1309V41.6309H7.07244ZM25.1116 18.4834C23.5308 18.4834 21.9858 18.0175 20.6719 17.145C19.3581 16.2725 18.3346 15.0327 17.7303 13.5827C17.126 12.1328 16.9679 10.5375 17.276 8.99843C17.584 7.45937 18.3444 6.04523 19.4614 4.935C20.5785 3.82475 22.0021 3.06831 23.5524 2.76182C25.1027 2.45533 26.7096 2.61266 28.1698 3.21379C29.63 3.8149 30.8775 4.83265 31.755 6.13786C32.6324 7.44304 33.1006 8.97718 33.1006 10.5463C33.1006 12.6504 32.2596 14.6689 30.7617 16.1576C29.2637 17.6465 27.2314 18.4834 25.1116 18.4834ZM25.1116 3.71847C23.7541 3.71847 22.4269 4.11853 21.2978 4.86839C20.1686 5.61827 19.288 6.68444 18.7679 7.93241C18.2478 9.18041 18.1117 10.5539 18.3769 11.8789C18.6421 13.204 19.2966 14.4207 20.2571 15.3754C21.2177 16.3301 22.4411 16.9798 23.7725 17.2431C25.1039 17.5063 26.484 17.3712 27.7384 16.8548C28.9927 16.3384 30.0654 15.4637 30.8203 14.3408C31.5752 13.2179 31.9783 11.8974 31.9783 10.5463C31.9783 8.73447 31.2541 6.99745 29.966 5.71722C28.678 4.43709 26.9318 3.71847 25.1116 3.71847Z"
      fill="#101010"
      stroke="#101010"
    />
  </Icon>
);
