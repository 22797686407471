import {Icon, IconProps} from '@chakra-ui/react';

export function BallIcon({fill = '#5AC57B', ...props}: IconProps) {
  return (
    <Icon width="48px" height="44px" viewBox="0 0 48 44" color={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="22.0432" cy="21.5217" r="21.5217" fill="currentColor" />
      <path
        d="M47.478 22.8823C47.478 11.0878 37.8234 1.49219 25.9563 1.49219C18.5033 1.49219 11.9249 5.27824 8.06098 11.0151C8.03874 11.0486 8.0122 11.0785 7.99642 11.1149C5.74811 14.4938 4.43457 18.5373 4.43457 22.8823C4.43457 33.1581 11.7634 41.762 21.4984 43.8083C21.5013 43.809 21.5035 43.8097 21.5063 43.8104C22.9425 44.1113 24.4304 44.2725 25.9563 44.2725C28.1293 44.2725 30.2262 43.948 32.2048 43.3498C32.2378 43.342 32.2694 43.3349 32.3009 43.3227C41.0811 40.6254 47.478 32.4829 47.478 22.8823ZM33.2027 41.4967L35.1497 35.7991L41.188 35.8754C39.048 38.3495 36.3126 40.2967 33.2027 41.4967ZM17.9954 36.5827L20.4496 42.0778C17.2249 41.1623 14.3303 39.4668 11.9858 37.2073L17.9954 36.5827ZM17.9531 35.1531L10.7476 35.9018C7.98063 32.7139 6.21011 28.6527 5.91742 24.1943L11.6967 19.6225L18.5535 21.8941L21.045 29.5147L17.9531 35.1531ZM8.49931 13.025L10.6156 18.6549L5.88155 22.4003C5.96333 18.9979 6.90455 15.8043 8.49931 13.025ZM22.261 28.6184L19.9769 21.6324L25.9563 17.3145L31.9358 21.6324L29.6516 28.6184H22.261ZM33.3591 21.8948L40.5064 19.5269L46.0232 23.6552C45.8682 27.6751 44.5123 31.392 42.3021 34.4644L35.121 34.3738L30.9659 29.2131L33.3591 21.8948ZM46.0174 21.8656L41.1801 18.2485L43.1464 12.5637C44.818 15.3045 45.8453 18.4745 46.0174 21.8656ZM42.1428 11.0778L39.6542 18.2727C39.6499 18.2842 39.6513 18.2963 39.6477 18.3077L32.8784 20.5501L26.6737 16.0696V8.3078L32.5828 4.03904C36.4403 5.38377 39.7632 7.8636 42.1428 11.0778ZM30.8582 3.52211L25.9563 7.06289L21.0544 3.5214C22.624 3.12925 24.2654 2.9182 25.9563 2.9182C27.6472 2.9182 29.2886 3.12925 30.8582 3.52211ZM19.3298 4.03975L25.2389 8.3078V16.0696L19.0342 20.5501L11.9844 18.2143L9.46133 11.5085C11.8574 8.08605 15.3016 5.44295 19.3298 4.03975ZM22.2044 42.4921L19.2229 35.8162L22.388 30.0445H29.7979L33.8713 35.1039L31.491 42.07C29.7319 42.5719 27.876 42.8465 25.9563 42.8465C24.6736 42.8465 23.421 42.7217 22.2044 42.4921Z"
        fill="#1B1B1B"
        stroke="#1B1B1B"
        strokeWidth="0.2"
      />
    </Icon>
  );
}
