import {Icon, IconProps} from '@chakra-ui/react';

export const FacebookIcon2 = (props: IconProps) => (
  <Icon width="14" height="26" viewBox="0 0 14 26" fill="none" {...props}>
    <path
      d="M10.0415 0.950684C8.38341 0.950684 6.79318 1.60938 5.62064 2.78185C4.44824 3.95433 3.78952 5.54453 3.78952 7.20267V10.6835H0.44386C0.275888 10.6835 0.139709 10.8196 0.139709 10.9877V15.5837C0.139709 15.7517 0.275888 15.8879 0.44386 15.8879H3.78952V25.3165C3.78952 25.4846 3.9257 25.6207 4.09367 25.6207H8.68972C8.85775 25.6207 8.99388 25.4846 8.99388 25.3165V15.8879H12.3691C12.5088 15.8879 12.6304 15.7928 12.6642 15.6575L13.8132 11.0615C13.8612 10.8695 13.7161 10.6835 13.5182 10.6835H8.99388V7.20267C8.99388 6.92482 9.10432 6.65835 9.30073 6.46188C9.49714 6.26541 9.76371 6.15504 10.0415 6.15504H13.5561C13.7242 6.15504 13.8603 6.01886 13.8603 5.85089V1.25483C13.8603 1.08686 13.7242 0.950684 13.5561 0.950684H10.0415Z"
      fill="#2B7EDE"
    />
  </Icon>
);
