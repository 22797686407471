import {Icon, IconProps} from '@chakra-ui/react';

export function PhoneIcon(props: IconProps) {
  return (
    <Icon width="18px" height="18px" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M5.57119 9.05694C6.29405 10.5348 7.49198 11.7273 8.97311 12.4434C9.08147 12.4948 9.20134 12.517 9.32092 12.508C9.44049 12.4989 9.55565 12.4589 9.65506 12.3918L11.8359 10.9376C11.9324 10.8733 12.0433 10.834 12.1588 10.8234C12.2742 10.8128 12.3905 10.8312 12.497 10.8768L16.577 12.6254C16.7156 12.6843 16.8313 12.7866 16.9067 12.9169C16.9821 13.0473 17.0131 13.1986 16.995 13.3481C16.866 14.3572 16.3737 15.2847 15.6101 15.9569C14.8466 16.6291 13.8642 16.9999 12.8469 17C9.70491 17 6.6916 15.7518 4.46988 13.5301C2.24815 11.3084 1 8.29509 1 5.1531C1.00005 4.13581 1.37091 3.15342 2.04312 2.38988C2.71533 1.62633 3.64281 1.13399 4.65189 1.00503C4.80139 0.986941 4.95271 1.01792 5.08306 1.09331C5.21342 1.1687 5.31574 1.28441 5.37461 1.423L7.12469 5.50651C7.16996 5.61214 7.1884 5.72732 7.17838 5.84179C7.16836 5.95627 7.13019 6.06649 7.06726 6.16265L5.61807 8.37695C5.55211 8.47656 5.51312 8.59157 5.5049 8.71076C5.49668 8.82994 5.51952 8.94922 5.57119 9.05694V9.05694Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
