import {Icon, IconProps} from '@chakra-ui/react';
import {useRouter} from 'next/router';

import {getLanguage} from '@eksab/util/helpers';

export function LeftChevronIcon({color = '#101010', scaleX = 1, ...props}: IconProps) {
  const locale = useRouter().locale;

  return (
    <Icon
      transform={'auto'}
      width="11px"
      height="17px"
      viewBox="0 0 11 17"
      fill="none"
      color={color}
      scaleX={getLanguage(locale) === 'ar' ? -1 * Number(scaleX) : scaleX}
      {...props}
    >
      <path
        d="M9.5 16L1.5 8.5L9.5 1"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
