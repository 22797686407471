import {Icon, IconProps} from '@chakra-ui/react';

export function GiftIcon(props: IconProps) {
  return (
    <Icon boxSize="8" viewBox="0 0 32 32" fill="none" color="#5AC57B" {...props}>
      <path
        d="M27.0188 8.9082H4.98125C3.67587 8.9082 2.61765 9.96642 2.61765 11.2718C2.61765 12.5772 3.67586 13.6354 4.98124 13.6354H27.0187C28.3241 13.6354 29.3824 12.5772 29.3824 11.2718C29.3824 9.96642 28.3241 8.9082 27.0188 8.9082Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M27.0547 14.4011L26.2402 24.6272C26.0656 27.1089 24.0292 28.9998 21.5854 28.9998H10.4141C7.97034 28.9998 5.93389 27.1089 5.75934 24.6272L4.94476 14.4011"
        fill="white"
      />
      <path
        d="M27.0547 14.4011L26.2402 24.6272C26.0656 27.1089 24.0292 28.9998 21.5854 28.9998H10.4141C7.97034 28.9998 5.93389 27.1089 5.75934 24.6272L4.94476 14.4011"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M16 8.9082V28.9988" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M10.1817 8.909C8.5525 8.909 7.27245 7.60902 7.27245 5.9545C7.27245 4.29998 8.5525 3 10.1817 3C11.8108 3 12.7418 3.5909 13.7891 5.24542C14.2545 5.83632 16.0001 8.909 16.0001 8.909"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M21.8184 8.909C23.4476 8.909 24.7277 7.60902 24.7277 5.9545C24.7277 4.29998 23.4476 3 21.8184 3C20.1893 3 19.2583 3.5909 18.211 5.24542C17.7456 5.83632 16 8.909 16 8.909"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </Icon>
  );
}
