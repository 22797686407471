import {Icon, IconProps} from '@chakra-ui/react';

export const CoinIcon = (props: IconProps) => (
  <Icon width="19px" height="19px" viewBox="0 0 19 19" fill="none" {...props}>
    <circle cx="9.5" cy="9.5" r="9.5" fill="#FED44C" />
    <circle cx="9.5" cy="9.5" r="8.5" stroke="url(#paint0_radial_4892_26632)" strokeWidth="2" />
    <circle cx="9.4999" cy="9.49844" r="7.1" stroke="url(#paint1_radial_4892_26632)" />
    <g filter="url(#filter0_i_4892_26632)">
      <circle cx="9.49973" cy="9.49826" r="7.38889" fill="#FDC616" />
    </g>
    <path
      d="M13.402 3.28483L13.1314 3.62873L13.4773 3.36L13.6056 4.41565L13.7311 3.35765L14.077 3.62873L13.8064 3.28483L14.8584 3.15564L13.8036 3.02973L14.0742 2.68254L13.7311 2.95409L13.6056 1.89844L13.4773 2.95409L13.1342 2.68254L13.4048 3.02973L12.35 3.15564L13.402 3.28483Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_4892_26632"
        x="2.11084"
        y="2.10938"
        width="14.7778"
        height="14.7773"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.85 0 0 0 0 0.669494 0 0 0 0 0.085 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4892_26632" />
      </filter>
      <radialGradient
        id="paint0_radial_4892_26632"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.5 9.5) rotate(90) scale(9.5)"
      >
        <stop offset="0.874289" stopColor="#FEE182" stopOpacity="0" />
        <stop offset="0.962342" stopColor="#E9B716" stopOpacity="0.2" />
        <stop offset="0.998615" stopColor="#FEE182" stopOpacity="0.1" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_4892_26632"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.4999 9.49844) rotate(90) scale(7.6)"
      >
        <stop offset="0.899477" stopColor="#FED44C" stopOpacity="0" />
        <stop offset="0.960709" stopColor="#E9B716" stopOpacity="0.3" />
        <stop offset="0.998615" stopColor="#E9B716" stopOpacity="0.1" />
      </radialGradient>
    </defs>
  </Icon>
);
