import {Icon, IconProps} from '@chakra-ui/react';

export function XIcon2(props: IconProps) {
  return (
    <Icon width="13px" height="12px" viewBox="0 0 13 12" fill="none" {...props}>
      <path
        d="M8.016 12H12.224L8.256 6.4L11.92 0.672H7.872L6.208 3.504L4.208 0.672H0L3.888 6.16L0.16 12H4.208L5.936 9.056L8.016 12Z"
        fill="url(#paint0_linear_504_1700)"
      />

      <defs>
        <linearGradient id="paint0_linear_504_1700" x1="6.5" y1="-6" x2="6.5" y2="18" gradientUnits="userSpaceOnUse">
          <stop offset="0.158337" stopColor="#FFCE84" />
          <stop offset="1" stopColor="#EC1958" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
