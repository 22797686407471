import {Icon, IconProps} from '@chakra-ui/react';

export function ProfileIcon2(props: IconProps) {
  return (
    <Icon width="53px" height="62px" viewBox="0 0 53 62" fill="none" {...props}>
      <path
        d="M25.6703 21.7136C24.8188 22.8582 23.8177 23.7848 22.7936 24.3761C21.4203 25.169 20.1033 25.3034 19.1323 24.7496C18.1613 24.1959 17.6158 22.9993 17.6159 21.4233C17.6159 20.2479 17.9196 18.9237 18.4886 17.6179C19.0576 16.3121 19.8663 15.0835 20.8125 14.0874C21.7587 13.0913 22.7998 12.3725 23.8042 12.0219C24.8086 11.6712 25.7312 11.7045 26.4553 12.1174C27.1795 12.5304 27.6726 13.3045 27.8723 14.3419C28.0721 15.3793 27.9695 16.6333 27.5775 17.9455C27.1856 19.2576 26.5219 20.5689 25.6703 21.7136Z"
        fill="#929292"
      />
      <path
        d="M10.4951 52.3247L10.4952 50.8391C10.4953 47.0959 11.7911 42.758 14.0974 38.7795C16.4038 34.8011 19.5318 31.508 22.7933 29.6248C26.0549 27.7416 29.1828 27.4225 31.4889 28.7377C33.7951 30.0528 35.0906 32.8946 35.0904 36.6378L35.0904 38.1234L10.4951 52.3247Z"
        fill="#929292"
      />
      <path d="M38.8263 38.7943L38.8262 40.28L35.0908 38.1233L35.0909 36.6377L38.8263 38.7943Z" fill="#929292" />
      <path
        d="M35.2237 30.8949C37.5298 32.2101 38.8253 35.0518 38.8252 38.795L35.0898 36.6384C35.0899 32.9241 33.8144 30.0974 31.5415 28.769L31.4883 28.7383C31.5061 28.7484 31.5238 28.7587 31.5415 28.769L35.2237 30.8949Z"
        fill="#929292"
      />
      <path d="M38.8258 40.2797L14.2305 54.481L10.4951 52.3244L35.0904 38.123L38.8258 40.2797Z" fill="#929292" />
      <path
        d="M26.5284 31.7816C29.7899 29.8984 32.9178 29.5793 35.2239 30.8945L31.4886 28.7379C29.1824 27.4227 26.0545 27.7418 22.793 29.625L26.5284 31.7816Z"
        fill="#929292"
      />
      <path d="M14.2305 54.4811L14.2306 52.9955L10.4952 50.8389L10.4951 52.3245L14.2305 54.4811Z" fill="#929292" />
      <path
        d="M31.3125 20.1019C30.9206 21.4141 30.2568 22.7254 29.4053 23.87L25.6699 21.7134C26.5215 20.5688 27.1852 19.2575 27.5771 17.9453L31.3125 20.1019Z"
        fill="#929292"
      />
      <path
        d="M29.4051 23.8705C28.5536 25.0151 27.5525 25.9417 26.5284 26.533L22.793 24.3764C23.8171 23.7851 24.8182 22.8585 25.6697 21.7139L29.4051 23.8705Z"
        fill="#929292"
      />
      <path
        d="M17.8321 40.9363C20.1384 36.9579 23.2664 33.6648 26.528 31.7816L22.7926 29.625C19.531 31.5082 16.403 34.8013 14.0967 38.7797L17.8321 40.9363Z"
        fill="#929292"
      />
      <path
        d="M14.2305 52.9955C14.2306 49.2523 15.5264 44.9144 17.8328 40.9359L14.0974 38.7793C11.791 42.7577 10.4953 47.0957 10.4951 50.8389L14.2305 52.9955Z"
        fill="#929292"
      />
      <path
        d="M31.6073 16.4984C31.8071 17.5358 31.7045 18.7899 31.3125 20.102L27.5771 17.9454C27.9691 16.6332 28.0717 15.3792 27.8719 14.3418L31.6073 16.4984Z"
        fill="#929292"
      />
      <path
        d="M26.5285 26.5326C25.1552 27.3255 23.8382 27.4599 22.8672 26.9061L19.1318 24.7495C20.1028 25.3033 21.4199 25.1689 22.7931 24.376L26.5285 26.5326Z"
        fill="#929292"
      />
      <path
        d="M30.1905 14.2738C30.9146 14.6868 31.4077 15.4609 31.6074 16.4983L27.8721 14.3416C27.6744 13.315 27.1894 12.5462 26.4775 12.1301L26.4551 12.1172C26.4626 12.1215 26.47 12.1258 26.4775 12.1301L30.1905 14.2738Z"
        fill="#929292"
      />
      <path
        d="M27.5391 14.1785C28.5435 13.8278 29.4661 13.8611 30.1902 14.274L26.4548 12.1174C25.7307 11.7045 24.8081 11.6712 23.8037 12.0219L27.5391 14.1785Z"
        fill="#929292"
      />
      <path
        d="M21.3516 23.5795C21.3515 25.1434 21.8886 26.3336 22.8456 26.8929L22.868 26.9058C22.8605 26.9016 22.8531 26.8972 22.8456 26.8929L19.1326 24.7492C18.1616 24.1954 17.6162 22.9989 17.6162 21.4229L21.3516 23.5795Z"
        fill="#929292"
      />
      <path
        d="M24.5479 16.2437C25.4941 15.2476 26.5352 14.5288 27.5396 14.1781L23.8042 12.0215C22.7998 12.3721 21.7587 13.091 20.8125 14.087L24.5479 16.2437Z"
        fill="#929292"
      />
      <path
        d="M21.3516 23.5792C21.3516 22.4039 21.6554 21.0796 22.2244 19.7738L18.489 17.6172C17.92 18.923 17.6163 20.2472 17.6162 21.4226L21.3516 23.5792Z"
        fill="#929292"
      />
      <path
        d="M22.2237 19.774C22.7927 18.4682 23.6014 17.2396 24.5475 16.2435L20.8122 14.0869C19.866 15.083 19.0573 16.3116 18.4883 17.6174L22.2237 19.774Z"
        fill="#929292"
      />
      <path
        d="M29.4064 23.8706C28.5548 25.0152 27.5537 25.9418 26.5296 26.5331C25.1563 27.326 23.8393 27.4604 22.8683 26.9066C21.8973 26.3529 21.3518 25.1563 21.3519 23.5803C21.3519 22.4049 21.6557 21.0807 22.2247 19.7749C22.7936 18.4691 23.6024 17.2405 24.5485 16.2444C25.4947 15.2484 26.5358 14.5295 27.5402 14.1789C28.5446 13.8282 29.4672 13.8615 30.1914 14.2744C30.9155 14.6874 31.4086 15.4615 31.6083 16.4989C31.8081 17.5363 31.7055 18.7903 31.3135 20.1025C30.9216 21.4146 30.2579 22.7259 29.4064 23.8706Z"
        fill="#ABABAB"
      />
      <path
        d="M25.8807 47.7543L14.2303 54.4812L14.2304 52.9956C14.2305 49.2524 15.5263 44.9145 17.8326 40.936C20.139 36.9576 23.267 33.6645 26.5285 31.7813C29.7901 29.8981 32.918 29.579 35.2241 30.8942C37.5303 32.2094 38.8258 35.0511 38.8256 38.7943L38.8256 40.2799L25.8807 47.7543Z"
        fill="#ABABAB"
      />
    </Icon>
  );
}
