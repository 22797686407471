import {Icon, IconProps} from '@chakra-ui/react';

export function StarIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6828 -1.02135e-06C9.37217 -1.22336e-06 7.1134 0.685189 5.19216 1.96892C3.27091 3.25265 1.77349 5.07727 0.889236 7.21204C0.00498499 9.34681 -0.226377 11.6959 0.224412 13.9621C0.675198 16.2284 1.78789 18.3101 3.42177 19.9439C5.05565 21.5778 7.13734 22.6905 9.4036 23.1413C11.6699 23.5921 14.0189 23.3607 16.1537 22.4765C18.2884 21.5922 20.1131 20.0948 21.3968 18.1736C22.6805 16.2523 23.3657 13.9936 23.3657 11.6829C23.3657 8.5844 22.1348 5.61281 19.9439 3.42184C17.7529 1.23087 14.7813 -7.50472e-07 11.6828 -1.02135e-06Z"
        fill="#FED44C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.683 -3.51438e-08C11.8177 -2.3364e-08 11.9512 0.00542736 12.085 0.0102169C9.05926 0.115203 6.19254 1.39098 4.08921 3.56856C1.98587 5.74614 0.810277 8.65537 0.810277 11.6829C0.810277 14.7104 1.98587 17.6196 4.08921 19.7972C6.19254 21.9748 9.05926 23.2506 12.085 23.3556C11.9512 23.36 11.8177 23.3658 11.683 23.3658C8.58446 23.3658 5.61287 22.1349 3.42191 19.9439C1.23094 17.753 6.35576e-05 14.7814 6.38285e-05 11.6829C6.40994e-05 8.5844 1.23094 5.61281 3.42191 3.42184C5.61288 1.23087 8.58447 -3.06023e-07 11.683 -3.51438e-08Z"
        fill="#E2B647"
      />
      <path
        d="M5.26722 5.78943C6.63086 4.42575 8.42514 3.57709 10.3443 3.38803C12.2636 3.19898 14.1889 3.68123 15.7925 4.75262C17.396 5.82401 18.5784 7.41825 19.1382 9.2637C19.6981 11.1091 19.6007 13.0916 18.8627 14.8733C18.1247 16.655 16.7918 18.1258 15.0911 19.0349C13.3903 19.944 11.4269 20.2353 9.53546 19.8591C7.64401 19.4829 5.94152 18.4625 4.71806 16.9718C3.49461 15.481 2.82589 13.6122 2.82585 11.6837C2.8244 10.5888 3.03933 9.50447 3.45831 8.49293C3.87728 7.48139 4.49203 6.56262 5.26722 5.78943ZM11.1615 2.03126C8.92829 2.03118 6.76412 2.80544 5.03777 4.22213C3.31141 5.63881 2.12968 7.61026 1.69392 9.80056C1.25817 11.9909 1.59535 14.2645 2.64803 16.2341C3.7007 18.2036 5.40373 19.7473 7.46693 20.6019C9.53014 21.4566 11.8259 21.5695 13.9629 20.9213C16.1 20.2731 17.9462 18.9039 19.187 17.0471C20.4278 15.1902 20.9863 12.9606 20.7675 10.7382C20.5487 8.51568 19.566 6.43784 17.9869 4.85867C17.0916 3.96097 16.0277 3.24905 14.8564 2.76383C13.685 2.27861 12.4294 2.02965 11.1615 2.03126Z"
        fill="#E2B647"
      />
      <path
        d="M10.4317 6.82828L9.53443 9.60812L6.6058 9.60237C6.44457 9.60246 6.28751 9.65363 6.15716 9.74851C6.0268 9.8434 5.92985 9.97713 5.8802 10.1305C5.83056 10.2839 5.83078 10.4491 5.88083 10.6024C5.93087 10.7556 6.02818 10.8891 6.15878 10.9837L6.15878 10.9853L8.52831 12.7015L7.61831 15.4839C7.56808 15.6421 7.57055 15.8123 7.62537 15.9689C7.68018 16.1256 7.78437 16.2602 7.92226 16.3525C8.06016 16.4449 8.22431 16.49 8.39003 16.481C8.55575 16.4721 8.71409 16.4096 8.84123 16.3029L11.1606 14.6106L13.5266 16.3364C13.6569 16.4314 13.8139 16.4827 13.9751 16.4829C14.1363 16.4832 14.2934 16.4323 14.4239 16.3376C14.5544 16.2429 14.6515 16.1093 14.7013 15.956C14.7511 15.8026 14.751 15.6375 14.701 15.4842L14.703 15.4842L13.7933 12.7015L16.1644 10.9856C16.2984 10.8875 16.3968 10.7486 16.445 10.5897C16.4932 10.4308 16.4885 10.2606 16.4316 10.1046C16.3747 9.94865 16.2687 9.81538 16.1295 9.72486C15.9904 9.63434 15.8255 9.59146 15.6599 9.60269L12.7862 9.60844L11.887 6.82349C11.8374 6.66899 11.7399 6.53428 11.6086 6.4389C11.4774 6.34351 11.3191 6.2924 11.1568 6.29297C10.9946 6.29354 10.8367 6.34576 10.7061 6.44206C10.5755 6.53837 10.4789 6.67375 10.4304 6.8286"
        fill="#E2B647"
      />
      <path
        d="M5.78822 5.78943C7.15185 4.42575 8.94614 3.57709 10.8653 3.38803C12.7846 3.19898 14.7099 3.68123 16.3134 4.75262C17.917 5.82401 19.0994 7.41825 19.6592 9.2637C20.2191 11.1091 20.1217 13.0916 19.3837 14.8733C18.6457 16.655 17.3128 18.1258 15.6121 19.0349C13.9113 19.944 11.9479 20.2353 10.0565 19.8591C8.16501 19.4829 6.46251 18.4625 5.23906 16.9718C4.0156 15.481 3.34688 13.6122 3.34684 11.6837C3.34537 10.5888 3.5603 9.50447 3.97928 8.49292C4.39825 7.48138 5.01301 6.56261 5.78822 5.78943ZM11.6825 2.03126C9.44929 2.03118 7.28512 2.80544 5.55876 4.22213C3.8324 5.63881 2.65067 7.61026 2.21492 9.80056C1.77916 11.9909 2.11635 14.2645 3.16902 16.2341C4.2217 18.2036 5.92472 19.7473 7.98793 20.6019C10.0511 21.4566 12.3469 21.5695 14.4839 20.9213C16.621 20.2731 18.4672 18.9039 19.708 17.0471C20.9488 15.1902 21.5073 12.9606 21.2885 10.7382C21.0697 8.51568 20.087 6.43784 18.5079 4.85867C17.6126 3.96095 16.5487 3.24903 15.3774 2.7638C14.206 2.27858 12.9504 2.02963 11.6825 2.03126Z"
        fill="white"
      />
      <path
        d="M10.9527 6.82852L10.0554 9.60835L7.1268 9.6026C6.96557 9.6027 6.80851 9.65386 6.67815 9.74874C6.5478 9.84363 6.45084 9.97736 6.4012 10.1308C6.35156 10.2842 6.35177 10.4493 6.40182 10.6026C6.45187 10.7559 6.54917 10.8894 6.67978 10.9839L6.67978 10.9855L9.04931 12.7017L8.1393 15.4841C8.08897 15.6424 8.09139 15.8127 8.1462 15.9694C8.20101 16.1261 8.30525 16.2608 8.44322 16.3532C8.5812 16.4456 8.74544 16.4906 8.91124 16.4816C9.07704 16.4726 9.23542 16.4099 9.36254 16.3031L11.6829 14.6112L14.0489 16.337C14.1792 16.4318 14.3361 16.4829 14.4972 16.483C14.6583 16.4831 14.8154 16.4322 14.9457 16.3376C15.0761 16.2429 15.1732 16.1095 15.2231 15.9563C15.273 15.8031 15.273 15.638 15.2233 15.4848L15.2252 15.4848L14.3139 12.7017L16.6854 10.9858C16.8193 10.8877 16.9178 10.7488 16.966 10.5899C17.0142 10.4311 17.0095 10.2608 16.9526 10.1049C16.8957 9.94889 16.7897 9.81562 16.6505 9.72509C16.5113 9.63457 16.3465 9.5917 16.1809 9.60292L13.3072 9.60867L12.408 6.82373C12.3585 6.66918 12.2611 6.53441 12.1298 6.43898C11.9985 6.34354 11.8403 6.2924 11.678 6.29297C11.5157 6.29354 11.3578 6.34579 11.2272 6.44214C11.0966 6.5385 11.0001 6.67394 10.9517 6.82884"
        fill="white"
      />
    </Icon>
  );
}
