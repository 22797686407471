import {Icon, IconProps} from '@chakra-ui/react';

export function ClockIcon2(props: IconProps) {
  return (
    <Icon width="17px" height="20px" viewBox="0 0 17 20" fill="none" {...props}>
      <path
        d="M8.61539 19.0003C12.8212 19.0003 16.2308 15.5908 16.2308 11.3849C16.2308 7.17905 12.8212 3.76953 8.61539 3.76953C4.40952 3.76953 1 7.17905 1 11.3849C1 15.5908 4.40952 19.0003 8.61539 19.0003Z"
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.61523 11.3838L12.042 7.95703" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.53809 1H10.6919" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
