import {Icon, IconProps} from '@chakra-ui/react';

export function ArrowDownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 50 54.239" {...props}>
      <path
        d="M328.138,158.234l-15.131,15.131a3.917,3.917,0,0,1-5.544,0l-15.131-15.131a3.92,3.92,0,1,1,5.544-5.544l8.446,8.446V151.027A23.041,23.041,0,0,0,283.2,128.1a3.913,3.913,0,1,1,0-7.827,30.89,30.89,0,0,1,30.947,30.752v10.142l8.446-8.446a3.926,3.926,0,0,1,2.772-1.141,3.983,3.983,0,0,1,2.772,1.141A3.877,3.877,0,0,1,328.138,158.234Z"
        transform="translate(-279.288 -120.275)"
        fill="#34495e"
      />
    </Icon>
  );
}
