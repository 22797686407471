import {Icon, IconProps} from '@chakra-ui/react';

export function XIcon({width = '32px', height = '32px', ...props}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M22.944 0C28.368 0 32 3.808 32 9.472V22.5456C32 28.1936 28.368 32 22.944 32H9.072C3.648 32 0 28.1936 0 22.5456V9.472C0 3.808 3.648 0 9.072 0H22.944ZM20.816 11.1536C20.272 10.608 19.392 10.608 18.832 11.1536L16 14L13.152 11.1536C12.592 10.608 11.712 10.608 11.168 11.1536C10.624 11.6976 10.624 12.5936 11.168 13.136L14.016 15.9856L11.168 18.8176C10.624 19.3776 10.624 20.2576 11.168 20.8C11.44 21.072 11.808 21.2176 12.16 21.2176C12.528 21.2176 12.88 21.072 13.152 20.8L16 17.9696L18.848 20.8C19.12 21.0896 19.472 21.2176 19.824 21.2176C20.192 21.2176 20.544 21.072 20.816 20.8C21.36 20.2576 21.36 19.3776 20.816 18.8336L17.968 15.9856L20.816 13.136C21.36 12.5936 21.36 11.6976 20.816 11.1536Z"
        fill="#EE6868"
      />
    </Icon>
  );
}
