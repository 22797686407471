import {Icon, IconProps} from '@chakra-ui/react';

export function EWalletIcon({color = '#ABABAB', ...props}: IconProps) {
  return (
    <Icon width="68px" height="61px" viewBox="0 0 68 61" fill="none" color={color} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68 53.5599C68 57.6698 64.6917 61 60.6087 61H7.3913C3.30835 61 0 57.6698 0 53.5599V10.4072C0 7.12018 2.64757 4.45513 5.91304 4.45513H35.731L47.7035 0.0892662C48.4618 -0.188994 49.3015 0.205332 49.5779 0.965712L50.8389 4.45513H56.1739C59.4394 4.45513 62.087 7.12018 62.087 10.4072V13.3833C65.3524 13.3833 68 16.0483 68 19.3354V53.5599ZM5.91304 7.43117C4.28104 7.43117 2.95652 8.76444 2.95652 10.4072C2.95652 12.05 4.28104 13.3833 5.91304 13.3833H11.0057H11.285C11.3043 13.3758 11.3161 13.3595 11.3353 13.352L27.5696 7.43117H5.91304ZM48.8979 7.7005L47.328 3.34952H47.3265L44.2946 4.45513H44.299L36.1479 7.43117H36.1331L19.8146 13.3833H50.9497L48.8979 7.7005ZM59.1304 10.4072C59.1304 8.76444 57.8059 7.43117 56.1739 7.43117H51.9121L54.0615 13.3833H59.1304V10.4072ZM62.087 16.3593H5.91304C4.83096 16.3593 3.8287 16.0453 2.95652 15.5335V53.5599C2.95652 56.0255 4.9433 58.024 7.3913 58.024H60.6087C63.0582 58.024 65.0435 56.0255 65.0435 53.5599V43.1437H59.1304C55.865 43.1437 53.2174 40.4787 53.2174 37.1916C53.2174 33.9046 55.865 31.2395 59.1304 31.2395H65.0435V19.3354C65.0435 17.6926 63.719 16.3593 62.087 16.3593ZM65.0435 40.1677V34.2156H59.1304C57.4984 34.2156 56.1739 35.5489 56.1739 37.1916C56.1739 38.8344 57.4984 40.1677 59.1304 40.1677H65.0435ZM59.1304 35.7036H62.087V38.6797H59.1304V35.7036Z"
        fill="currentColor"
      />
      <path
        d="M22.1943 22.5761C22.9396 22.5528 23.567 23.1033 23.5819 23.8484C23.5987 24.2495 23.4439 24.5943 23.1749 24.8633C22.9444 25.0938 22.6378 25.2484 22.2747 25.2696C14.1525 25.6783 13.2272 33.9371 13.187 34.2812C13.1255 35.0267 12.4537 35.5845 11.7281 35.5122C10.9833 35.4591 10.452 34.8125 10.5135 34.067C10.8789 30.2438 13.7665 22.9866 22.1943 22.5761ZM21.7502 33.5834C21.2699 34.0638 20.9795 34.7341 20.9949 35.4027C20.991 36.0905 21.2548 36.7386 21.7298 37.2136C22.2048 37.6885 22.8529 37.9524 23.5407 37.9485C24.2285 37.9446 24.8796 37.6735 25.36 37.1931C25.8403 36.7128 26.1307 36.0425 26.1153 35.3739C26.1192 34.686 25.8554 34.0379 25.3804 33.563C24.9054 33.088 24.2573 32.8242 23.5695 32.8281C22.8817 32.8319 22.2306 33.1031 21.7502 33.5834ZM15.8435 34.1325C15.7438 34.8782 16.2751 35.5248 17.0197 35.6161C17.7643 35.7075 18.4362 35.1496 18.5167 34.4232C18.5365 34.2893 19.0153 30.6948 22.3404 30.5614C22.7036 30.5403 23.0101 30.3857 23.2407 30.1551C23.4905 29.9054 23.6645 29.5414 23.6476 29.1402C23.6327 28.3952 23.0055 27.8065 22.2601 27.868C17.8074 28.065 16.1226 32.0676 15.8435 34.1325Z"
        fill="currentColor"
      />
    </Icon>
  );
}
