import {Icon, IconProps} from '@chakra-ui/react';

export function AttachmentIcon(props: IconProps) {
  return (
    <Icon width="50px" height="46px" viewBox="0 0 50 46" fill="none" xmlns="http://www.w3.org/2000/Icon" {...props}>
      <path
        d="M9.21325 46C6.72877 46 4.45134 45.0602 2.69151 43.2849C0.931675 41.5096 0 39.2123 0 36.706C0 34.1998 0.931675 31.9024 2.69151 30.1271L28.9855 3.60272C33.7474 -1.20091 41.6149 -1.20091 46.3768 3.60272C48.6542 5.90011 50 9.03291 50 12.3746C50 15.7162 48.7578 18.7446 46.3768 21.1464L26.7081 40.9875L24.6377 38.899L44.3064 19.0579C47.9296 15.403 47.9296 9.3462 44.3064 5.69126C40.6832 2.03632 34.6791 2.03632 31.0559 5.69126L4.7619 32.2157C3.51967 33.3643 2.89855 35.0352 2.89855 36.706C2.89855 38.3769 3.51967 40.0477 4.7619 41.1964C7.24638 43.7026 11.1801 43.7026 13.6646 41.1964L33.3333 21.3553C34.5756 20.1022 34.5756 18.0136 33.3333 16.7605C32.0911 15.5074 30.0207 15.5074 28.7785 16.7605L15.6315 30.0227L13.5611 27.9342L26.7081 14.672C29.089 12.2702 32.9193 12.2702 35.3002 14.672C37.6812 17.0738 37.6812 20.9376 35.3002 23.3394L15.6315 43.1805C13.9752 45.0602 11.6977 46 9.21325 46Z"
        fill="#ABABAB"
      />
    </Icon>
  );
}
