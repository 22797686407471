import {Icon, IconProps} from '@chakra-ui/react';

export function WhistleIcon(props: IconProps) {
  return (
    <Icon width="24px" height="16px" viewBox="0 0 24 16" fill="none" {...props}>
      <path
        d="M15.2494 5.7617L14.2892 5.90989L14.4097 6.87403C14.4597 7.27423 14.502 7.66881 14.502 8C14.502 11.5977 11.5997 14.5 8.00195 14.5C4.40424 14.5 1.50195 11.5977 1.50195 8C1.50195 4.40228 4.40424 1.5 8.00195 1.5H22.502V4.64248L15.2494 5.7617Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Icon>
  );
}
