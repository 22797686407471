import {Icon, IconProps} from '@chakra-ui/react';

export function CheckIcon(props: IconProps) {
  return (
    <Icon width="24px" height="25px" viewBox="0 0 24 25" fill="none" color="white" {...props}>
      <g clipPath="url(#clip0_578_3051)">
        <path
          d="M20.6535 8.9222L20.6537 8.92203C21.2389 8.33677 21.2389 7.40019 20.6537 6.81492C20.0684 6.22966 19.1318 6.22966 18.5466 6.81492L18.8989 7.16731L18.5466 6.81492L9.00012 16.3614L5.85367 13.2149C5.26841 12.6297 4.33183 12.6297 3.74656 13.2149C3.1613 13.8002 3.1613 14.7368 3.74656 15.322L7.93656 19.512C8.52183 20.0973 9.46841 20.0973 10.0537 19.512L20.6535 8.9222Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_578_3051">
          <rect width="24" height="24" fill="currentColor" transform="translate(0 0.868408)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
