import {Icon, IconProps} from '@chakra-ui/react';

export const EyeIcon = (props: IconProps) => (
  <Icon width="20px" height="12px" viewBox="0 0 20 12" fill="none" {...props}>
    <path
      d="M10 12C15.3701 12 19.6198 6.50014 19.7977 6.26614L20 6L19.7977 5.73386C19.6198 5.49986 15.3701 0 10 0C4.62987 0 0.380227 5.49986 0.202266 5.73386L0 6L0.202266 6.26614C0.380227 6.50014 4.62987 12 10 12ZM10 0.857143C14.323 0.857143 18.0206 4.91143 18.9351 6C18.0218 7.08943 14.3291 11.1429 10 11.1429C5.67703 11.1429 1.9794 7.08857 1.06488 6C1.97817 4.91057 5.67085 0.857143 10 0.857143Z"
      fill="#ABABAB"
    />
    <path
      d="M10.0016 9.30896C11.743 9.30896 13.1595 7.83494 13.1595 6.02362C13.1595 4.2123 11.743 2.73828 10.0016 2.73828C8.26024 2.73828 6.84375 4.2123 6.84375 6.02362C6.84375 7.83494 8.26024 9.30896 10.0016 9.30896ZM10.0016 3.46836C11.3557 3.46836 12.4578 4.61457 12.4578 6.02362C12.4578 7.43266 11.3557 8.57888 10.0016 8.57888C8.64761 8.57888 7.5455 7.43266 7.5455 6.02362C7.5455 4.61457 8.64761 3.46836 10.0016 3.46836Z"
      fill="#ABABAB"
    />
  </Icon>
);
