import {Icon, IconProps} from '@chakra-ui/react';

export function DollarSignIcon(props: IconProps) {
  return (
    <Icon width="22px" height="22px" viewBox="0 0 22 22" fill="none" {...props}>
      <circle cx="11" cy="11" r="10.5" stroke="#101010" />
      <path
        d="M13.656 12.6676C13.656 13.2201 13.459 13.6887 13.0649 14.0734C12.6774 14.4581 12.1125 14.6819 11.3703 14.7449V15.9304H10.7398V14.7449C10.2603 14.7169 9.80383 14.6225 9.37033 14.4616C8.9434 14.2937 8.60186 14.0839 8.3457 13.8321L8.71024 13.0138C8.94669 13.2446 9.24554 13.4369 9.60679 13.5908C9.96803 13.7377 10.3457 13.8251 10.7398 13.8531V11.4191C10.28 11.3002 9.89579 11.1708 9.58708 11.0309C9.28495 10.891 9.02879 10.6846 8.81861 10.4119C8.615 10.1391 8.51319 9.78237 8.51319 9.34173C8.51319 8.78917 8.70038 8.32055 9.07477 7.93586C9.45572 7.55117 10.0107 7.32735 10.7398 7.2644V6.06836H11.3703V7.25391C11.7447 7.26789 12.106 7.33084 12.4541 7.44275C12.8088 7.55466 13.1142 7.70504 13.3703 7.89389L13.0452 8.73322C12.5132 8.3765 11.9549 8.18066 11.3703 8.14569V10.5902C11.8498 10.7161 12.2439 10.849 12.5526 10.9889C12.8613 11.1218 13.1207 11.3246 13.3309 11.5974C13.5477 11.8632 13.656 12.2199 13.656 12.6676ZM9.47871 9.29976C9.47871 9.60052 9.58708 9.83483 9.80383 10.0027C10.0206 10.1706 10.3326 10.3104 10.7398 10.4224V8.15618C10.3194 8.21214 10.0042 8.34503 9.79398 8.55486C9.5838 8.7577 9.47871 9.006 9.47871 9.29976ZM11.3703 13.8426C11.8104 13.8006 12.1388 13.6817 12.3556 13.4859C12.5789 13.2831 12.6905 13.0313 12.6905 12.7305C12.6905 12.4227 12.5756 12.1884 12.3457 12.0276C12.1224 11.8597 11.7973 11.7128 11.3703 11.5869V13.8426Z"
        fill="#101010"
      />
    </Icon>
  );
}
