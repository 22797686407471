import {Icon, IconProps} from '@chakra-ui/react';

export function UnknownErrorIcon(props: IconProps) {
  return (
    <Icon width="384" height="392" viewBox="0 0 384 392" fill="none" {...props}>
      <path
        d="M169.776 61.3276C182.282 51.0805 184.541 33.3714 174.61 22.4412C165.73 12.667 149.915 11.8788 138.25 20.5494C126.743 29.1149 123.013 43.8287 128.582 54.9691C128.845 55.4946 128.687 56.2303 128.162 56.6507L103.309 77.0398C101.365 78.6163 100.787 81.3489 102.1 83.2406C103.519 85.2901 106.566 85.5003 108.616 83.7661L133.626 63.2719C134.152 62.8515 134.887 62.799 135.36 63.1668C144.766 70.4712 159.215 70.0508 169.776 61.3276Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.776 61.3276C182.282 51.0805 184.541 33.3714 174.61 22.4412C165.73 12.667 149.915 11.8788 138.25 20.5494C126.743 29.1149 123.013 43.8287 128.582 54.9691C128.845 55.4946 128.687 56.2303 128.162 56.6507L103.309 77.0398C101.365 78.6163 100.787 81.3489 102.1 83.2406C103.519 85.2901 106.566 85.5003 108.616 83.7661L133.626 63.2719C134.152 62.8515 134.887 62.799 135.36 63.1668C144.766 70.4712 159.215 70.0508 169.776 61.3276Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.733 55.1793C156.326 62.0632 144.504 61.4852 138.251 53.8656C131.998 46.2459 133.732 34.5274 142.139 27.6435C150.546 20.7595 162.368 21.3376 168.621 28.9572C174.874 36.5769 173.14 48.2953 164.733 55.1793Z"
        fill="white"
      />
      <path
        d="M164.74 55.1865C173.131 48.3017 174.874 36.5544 168.634 28.9482C162.394 21.3421 150.534 20.7574 142.143 27.6422C133.753 34.5271 132.009 46.2744 138.249 53.8806C144.489 61.4867 156.35 62.0714 164.74 55.1865Z"
        stroke="black"
        strokeWidth="3.0277"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.969 29.4835C149.442 29.2733 149.915 29.1157 150.44 28.958"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.457 39.3097C141.193 36.4721 142.874 33.7395 145.396 31.6901C145.764 31.4273 146.079 31.1121 146.447 30.9019"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.509 64.0615C185.014 53.8144 187.273 36.1053 177.343 25.175C168.463 15.4009 152.647 14.6126 140.982 23.2833C129.475 31.8488 125.745 46.5626 131.314 57.703C131.577 58.2285 131.42 58.9642 130.894 59.3846L106.041 79.7737C104.097 81.3502 103.519 84.0828 104.832 85.9745C106.251 88.024 109.299 88.2342 111.348 86.5L136.359 66.0058C136.884 65.5854 137.62 65.5329 138.093 65.9007C147.498 73.2051 161.895 72.7321 172.509 64.0615Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.509 64.0615C185.014 53.8144 187.273 36.1053 177.343 25.175C168.463 15.4009 152.647 14.6126 140.982 23.2833C129.475 31.8488 125.745 46.5626 131.314 57.703C131.577 58.2285 131.42 58.9642 130.894 59.3846L106.041 79.7737C104.097 81.3502 103.519 84.0828 104.832 85.9745C106.251 88.024 109.299 88.2342 111.348 86.5L136.359 66.0058C136.884 65.5854 137.62 65.5329 138.093 65.9007C147.498 73.2051 161.895 72.7321 172.509 64.0615Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.413 57.86C159.006 64.7439 147.183 64.1659 140.931 56.5462C134.678 48.9266 136.412 37.2081 144.819 30.3241C153.226 23.4402 165.048 24.0182 171.301 31.6379C177.554 39.2575 175.82 51.0285 167.413 57.86Z"
        fill="white"
      />
      <path
        d="M167.413 57.8799C175.804 50.995 177.547 39.2477 171.308 31.6416C165.068 24.0354 153.207 23.4507 144.817 30.3356C136.426 37.2205 134.683 48.9678 140.923 56.5739C147.163 64.1801 159.023 64.7648 167.413 57.8799Z"
        stroke="black"
        strokeWidth="3.0277"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.703 32.1627C152.175 31.9525 152.648 31.7949 153.174 31.6372"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.189 41.9894C143.925 39.1518 145.606 36.4192 148.128 34.3698C148.496 34.107 148.811 33.7917 149.179 33.5815"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M238.031 124.019C245.282 117.923 249.906 108.78 249.906 98.5852C249.906 80.2455 235.036 65.374 216.698 65.374C211.812 65.374 207.135 66.425 202.932 68.3693C191.477 73.5717 183.491 85.1851 183.491 98.5852C183.491 112.09 191.582 123.704 203.142 128.906L199.622 160.961L246.701 159.805L238.031 124.019Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.031 124.019C245.282 117.923 249.906 108.78 249.906 98.5852C249.906 80.2455 235.036 65.374 216.698 65.374C211.812 65.374 207.135 66.425 202.932 68.3693C191.477 73.5717 183.491 85.1851 183.491 98.5852C183.491 112.09 191.582 123.704 203.142 128.906L199.622 160.961L246.701 159.805L238.031 124.019Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M249.905 98.5322C249.905 108.779 245.281 117.923 238.03 123.966C225.525 127.645 213.808 119.552 214.964 111.302C216.12 103.051 204.875 95.2742 204.875 95.2742C204.875 95.2742 197.729 89.6514 197.467 81.9792C197.204 74.307 202.984 68.2639 202.984 68.2639C207.187 66.3721 211.811 65.2686 216.75 65.2686C235.035 65.3737 249.905 80.2451 249.905 98.5322Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.905 98.5322C249.905 108.779 245.281 117.923 238.03 123.966C225.525 127.645 213.808 119.552 214.964 111.302C216.12 103.051 204.875 95.2742 204.875 95.2742C204.875 95.2742 197.729 89.6514 197.467 81.9792C197.204 74.307 202.984 68.2639 202.984 68.2639C207.187 66.3721 211.811 65.2686 216.75 65.2686C235.035 65.3737 249.905 80.2451 249.905 98.5322Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.508 95.6963C206.557 92.2806 210.025 90.7041 213.703 90.7041C219.641 90.7041 223.949 96.6422 223.214 102.633C222.583 107.572 218.8 112.722 214.071 114.141"
        fill="white"
      />
      <path
        d="M204.508 95.6963C206.557 92.2806 210.025 90.7041 213.703 90.7041C219.641 90.7041 223.949 96.6422 223.214 102.633C222.583 107.572 218.8 112.722 214.071 114.141"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M216.698 131.745C209.184 139.942 201.933 139.785 201.933 139.785L203.142 128.854C207.293 130.746 211.864 131.745 216.698 131.745Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.698 131.745C209.184 139.942 201.933 139.785 201.933 139.785L203.142 128.854C207.293 130.746 211.864 131.745 216.698 131.745Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.147 313.092C137.147 321.5 130.316 328.332 121.909 328.332C113.502 328.332 106.672 321.5 106.672 313.092C106.672 304.684 113.502 297.853 121.909 297.853C130.369 297.906 137.147 304.684 137.147 313.092Z"
        fill="black"
      />
      <path
        d="M300.977 102.422C300.977 107.361 296.984 111.355 292.045 111.355C287.106 111.355 283.112 107.361 283.112 102.422C283.112 97.482 287.106 93.4883 292.045 93.4883C296.984 93.4883 300.977 97.482 300.977 102.422Z"
        fill="black"
      />
      <path
        d="M64.5311 114.981C64.5311 114.981 71.9923 104.314 74.7245 94.1716C77.4568 84.0296 77.9822 75.937 88.018 72.0484C100.208 67.3715 105.988 64.3762 111.873 60.9079C117.022 57.8601 124.641 57.2295 124.746 59.384C124.851 61.5385 106.829 79.8782 106.146 83.7669C106.146 83.7669 117.442 74.5707 123.17 76.2523C123.853 76.4625 124.115 77.3033 123.643 77.8288C121.541 80.141 116.181 86.2367 113.397 90.5457C109.876 95.9583 100.576 101.371 95.2165 103.42C91.0656 104.997 87.335 114.561 84.9705 125.701C82.501 136.842 64.5311 114.981 64.5311 114.981Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.5311 114.981C64.5311 114.981 71.9923 104.314 74.7245 94.1716C77.4568 84.0296 77.9822 75.937 88.018 72.0484C100.208 67.3715 105.988 64.3762 111.873 60.9079C117.022 57.8601 124.641 57.2295 124.746 59.384C124.851 61.5385 106.829 79.8782 106.146 83.7669C106.146 83.7669 117.442 74.5707 123.17 76.2523C123.853 76.4625 124.115 77.3033 123.643 77.8288C121.541 80.141 116.181 86.2367 113.397 90.5457C109.876 95.9583 100.576 101.371 95.2165 103.42C91.0656 104.997 87.335 114.561 84.9705 125.701C82.501 136.842 64.5311 114.981 64.5311 114.981Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.4302 90.021L114.133 121.918C114.133 121.918 108.091 137.788 104.202 151.031C99.8937 165.587 101.68 198.167 139.512 189.812C164.575 184.294 193.001 134.267 246.595 143.306C293.201 151.136 314.114 211.567 317.739 253.029C321.89 301.006 306.547 346.672 267.928 368.69C244.546 381.985 203.457 383.771 192.843 384.036C191.53 384.087 190.479 382.878 190.636 381.564C192.528 367.954 199.937 301.742 169.882 263.223C169.882 263.223 94.7445 267.585 60.1183 221.657C24.0734 173.942 62.4302 90.021 62.4302 90.021Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.4302 90.021L114.133 121.918C114.133 121.918 108.091 137.788 104.202 151.031C99.8937 165.587 101.68 198.167 139.512 189.812C164.575 184.294 193.001 134.267 246.595 143.306C293.201 151.136 314.114 211.567 317.739 253.029C321.89 301.006 306.547 346.672 267.928 368.69C244.546 381.985 203.457 383.771 192.843 384.036C191.53 384.087 190.479 382.878 190.636 381.564C192.528 367.954 199.937 301.742 169.882 263.223C169.882 263.223 94.7445 267.585 60.1183 221.657C24.0734 173.942 62.4302 90.021 62.4302 90.021Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.129 59.0688C116.129 59.0688 111.4 61.4336 107.775 66.0579C104.938 69.6838 104.044 70.6822 104.044 70.6822"
        fill="white"
      />
      <path
        d="M116.129 59.0688C116.129 59.0688 111.4 61.4336 107.775 66.0579C104.938 69.6838 104.044 70.6822 104.044 70.6822"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.784 65.8467L94.2721 71.7322"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.6505 61.9577C62.7456 56.8604 67.9999 63.0612 74.095 57.9639C80.19 52.8667 74.9882 46.6133 81.0833 41.4635C87.1783 36.3662 92.4327 42.567 98.5277 37.4697"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.0217 269.791C58.3845 277.043 50.9758 280.459 54.3386 287.658C57.7014 294.91 65.0575 291.494 68.4203 298.693C71.7831 305.945 64.3744 309.361 67.7372 316.56"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M332.925 128.854C333.45 136.789 325.359 137.367 325.884 145.302C326.409 153.237 334.554 152.712 335.079 160.647C335.605 168.582 327.513 169.16 328.038 177.095"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M336.602 214.351C343.748 217.819 340.228 225.176 347.374 228.644C354.52 232.113 358.093 224.808 365.291 228.277C372.437 231.745 368.917 239.102 376.063 242.57"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M336.077 337.737C343.065 333.901 346.954 341.047 353.994 337.264C360.983 333.428 357.094 326.281 364.083 322.498C371.071 318.662 374.959 325.808 382 322.025"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.58132 169.474C6.94411 176.726 -0.464528 180.142 2.89826 187.341C6.26104 194.593 13.6171 191.177 16.9799 198.376C20.3427 205.628 12.9341 209.044 16.2969 216.243"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.275 347.721C99.2633 351.504 95.4276 358.651 102.416 362.487C109.404 366.271 113.292 359.124 120.333 362.907C127.322 366.691 123.486 373.838 130.474 377.674"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.68 287.08C163.576 294.962 155.537 293.859 154.381 301.741C153.278 309.624 161.317 310.78 160.213 318.662C159.11 326.545 151.071 325.441 149.915 333.323"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.657 62.2207C280.433 64.0599 278.542 71.9949 286.266 73.8341C294.042 75.6733 295.881 67.7909 303.658 69.6301C311.434 71.4694 309.542 79.4043 317.266 81.2435"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.262 2C228.625 9.2518 221.216 12.6675 224.579 19.8667C227.942 27.1185 235.298 23.7028 238.661 30.9021C242.024 38.1539 234.615 41.5696 237.978 48.7688"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.218 130.535C133.942 128.644 135.886 136.579 143.61 134.687C151.387 132.795 149.442 124.913 157.219 123.021C164.995 121.129 166.887 129.064 174.611 127.172"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.977 17.2388C112.977 18.8153 111.663 20.1291 110.087 20.1291C108.511 20.1291 107.197 18.8153 107.197 17.2388C107.197 15.6624 108.511 14.3486 110.087 14.3486C111.663 14.3486 112.977 15.6624 112.977 17.2388Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.6502 277.833C101.125 277.222 101.825 275.531 101.214 274.056C100.603 272.581 98.9128 271.881 97.4382 272.492C95.9636 273.103 95.2634 274.794 95.8742 276.268C96.485 277.743 98.1756 278.443 99.6502 277.833Z"
        stroke="black"
        strokeWidth="3.02774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M302.114 151.029C303.588 150.418 304.289 148.727 303.678 147.253C303.067 145.778 301.377 145.077 299.902 145.688C298.427 146.299 297.727 147.99 298.338 149.465C298.949 150.939 300.639 151.64 302.114 151.029Z"
        stroke="black"
        strokeWidth="3.02774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M368.391 186.553C368.391 188.13 367.077 189.443 365.501 189.443C363.925 189.443 362.611 188.13 362.611 186.553C362.611 184.977 363.925 183.663 365.501 183.663C367.13 183.663 368.391 184.977 368.391 186.553Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M356.307 284.19C356.307 285.766 354.993 287.08 353.417 287.08C351.84 287.08 350.527 285.766 350.527 284.19C350.527 282.613 351.84 281.299 353.417 281.299C354.993 281.299 356.307 282.56 356.307 284.19Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M313.118 390.287C314.706 390.129 315.866 388.714 315.709 387.126C315.552 385.537 314.137 384.377 312.549 384.535C310.96 384.692 309.8 386.107 309.958 387.695C310.115 389.284 311.53 390.444 313.118 390.287Z"
        stroke="black"
        strokeWidth="3.02757"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M286.055 19.8668C286.055 21.4433 284.742 22.757 283.165 22.757C281.589 22.757 280.276 21.4433 280.276 19.8668C280.276 18.2903 281.589 16.9766 283.165 16.9766C284.742 16.9766 286.055 18.2903 286.055 19.8668Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.502 90.65C365.502 92.2264 364.188 93.5402 362.612 93.5402C361.036 93.5402 359.722 92.2264 359.722 90.65C359.722 89.0735 361.036 87.7598 362.612 87.7598C364.188 87.7598 365.502 89.0735 365.502 90.65Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.6153 358.39C54.2035 358.232 55.3635 356.817 55.2063 355.229C55.049 353.64 53.634 352.48 52.0458 352.638C50.4576 352.795 49.2975 354.21 49.4548 355.798C49.6121 357.387 51.0271 358.547 52.6153 358.39Z"
        stroke="black"
        strokeWidth="3.02757"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8735 269.792C17.8735 271.368 16.5599 272.682 14.9836 272.682C13.4073 272.682 12.0937 271.368 12.0937 269.792C12.0937 268.215 13.4073 266.901 14.9836 266.901C16.6124 266.901 17.8735 268.215 17.8735 269.792Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9398 116.817C23.4144 116.206 24.1146 114.515 23.5038 113.041C22.893 111.566 21.2025 110.866 19.7279 111.476C18.2533 112.087 17.5531 113.778 18.1639 115.253C18.7747 116.728 20.4652 117.428 21.9398 116.817Z"
        stroke="black"
        strokeWidth="3.02774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.684 359.756C168.272 359.599 169.432 358.184 169.275 356.596C169.117 355.007 167.702 353.847 166.114 354.004C164.526 354.162 163.366 355.577 163.523 357.165C163.68 358.753 165.095 359.914 166.684 359.756Z"
        stroke="black"
        strokeWidth="3.02757"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M210.47 34.8394C211.944 34.2286 212.644 32.5378 212.034 31.0631C211.423 29.5883 209.732 28.888 208.258 29.4989C206.783 30.1098 206.083 31.8005 206.694 33.2752C207.304 34.75 208.995 35.4503 210.47 34.8394Z"
        stroke="black"
        strokeWidth="3.02774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.568 162.486C137.568 164.062 136.254 165.376 134.678 165.376C133.102 165.376 131.788 164.062 131.788 162.486C131.788 160.909 133.102 159.596 134.678 159.596C136.254 159.596 137.568 160.857 137.568 162.486Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.441 98.0607C150.441 99.6372 149.127 100.951 147.551 100.951C145.975 100.951 144.661 99.6372 144.661 98.0607C144.661 96.4842 145.975 95.1705 147.551 95.1705C149.127 95.1179 150.441 96.4317 150.441 98.0607Z"
        stroke="black"
        strokeWidth="3.02767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
