import {Icon, IconProps} from '@chakra-ui/react';

export function RefreshIcon(props: IconProps) {
  return (
    <Icon width="22px" height="22px" viewBox="0 0 22 22" fill="none" color="white" {...props}>
      <path
        d="M1.5 7.5C3.615 4.81 5.755 1.5 11 1.5C12.8789 1.5 14.7157 2.05717 16.2779 3.10104C17.8402 4.14491 19.0578 5.62861 19.7769 7.36451C20.4959 9.10041 20.684 11.0105 20.3175 12.8534C19.9509 14.6962 19.0461 16.3889 17.7175 17.7175C16.3889 19.0461 14.6962 19.9509 12.8534 20.3175C11.0105 20.684 9.10041 20.4959 7.36451 19.7769C5.62861 19.0578 4.14491 17.8402 3.10104 16.2779C2.05717 14.7157 1.5 12.8789 1.5 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M1.5 2.5V7.5H6.5" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
    </Icon>
  );
}
