import {Icon, IconProps} from '@chakra-ui/react';

export function CoinsIcon4({color = '#ABABAB', ...props}: IconProps) {
  return (
    <Icon width="60px" height="57px" viewBox="0 0 60 57" fill="none" color={color} {...props}>
      <path
        d="M8.01367 32.1992V43.664C8.01367 49.3964 17.6264 55.1288 33.0068 55.1288C48.3872 55.1288 57.9999 49.3964 57.9999 43.664V32.1992"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01367 32.2025C8.01367 38.5344 19.2035 43.6674 33.0068 43.6674C46.8101 43.6674 58 38.5344 58 32.2025C58 29.6323 56.1562 27.2597 53.0424 25.3477"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.002 43.668V55.1325"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.3887 41.3086V52.7735"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6289 41.3086V52.7735"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.31641 13.3359V24.8007C2.31641 30.5331 11.9292 36.2655 27.3095 36.2655C42.6899 36.2655 52.3027 30.5331 52.3027 24.8007V13.3359"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3095 24.7966C41.1129 24.7966 52.3027 19.6637 52.3027 13.3319C52.3027 7.00011 41.1129 1.86719 27.3095 1.86719C13.5062 1.86719 2.31641 7.00011 2.31641 13.3319C2.31641 19.6637 13.5062 24.7966 27.3095 24.7966Z"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3047 24.7969V36.2618"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.6914 22.4336V33.8983"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9316 22.4336V33.8983"
        stroke="currentColor"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
