import {Icon, IconProps} from '@chakra-ui/react';

export function DocumentIcon(props: IconProps) {
  return (
    <Icon width="56px" height="74px" viewBox="0 0 56 74" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4118 74H6.58824C2.94988 74 0 71.1188 0 67.5652V6.43478C0 2.88117 2.94988 0 6.58824 0H37.8379C37.8412 0 37.8461 0 37.8494 0H37.8824C38.4094 0 38.8591 0.259 39.1605 0.635435L55.3494 16.4473C55.7365 16.7417 56 17.1809 56 17.6957V17.7294C56 17.7327 56 17.7343 56 17.7375V67.5652C56 71.1188 53.0501 74 49.4118 74ZM39.5294 5.439V16.087H50.4313L39.5294 5.439ZM52.7059 19.3043H37.8824C36.9715 19.3043 36.2353 18.5837 36.2353 17.6957V3.21739H6.58824C4.76988 3.21739 3.29412 4.65878 3.29412 6.43478V67.5652C3.29412 69.3412 4.76988 70.7826 6.58824 70.7826H49.4118C51.2301 70.7826 52.7059 69.3412 52.7059 67.5652V19.3043ZM42.8235 61.1304H13.1765C12.2656 61.1304 11.5294 60.4113 11.5294 59.5217C11.5294 58.6337 12.2656 57.913 13.1765 57.913H42.8235C43.7344 57.913 44.4706 58.6337 44.4706 59.5217C44.4706 60.4113 43.7344 61.1304 42.8235 61.1304ZM42.8235 48.2609H13.1765C12.2656 48.2609 11.5294 47.5418 11.5294 46.6522C11.5294 45.7642 12.2656 45.0435 13.1765 45.0435H42.8235C43.7344 45.0435 44.4706 45.7642 44.4706 46.6522C44.4706 47.5418 43.7344 48.2609 42.8235 48.2609ZM42.8235 35.3913H13.1765C12.2656 35.3913 11.5294 34.6722 11.5294 33.7826C11.5294 32.8946 12.2656 32.1739 13.1765 32.1739H42.8235C43.7344 32.1739 44.4706 32.8946 44.4706 33.7826C44.4706 34.6722 43.7344 35.3913 42.8235 35.3913Z"
        fill="black"
      />
    </Icon>
  );
}
