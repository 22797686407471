import {Icon, IconProps} from '@chakra-ui/react';

export function PlusIcon(props: IconProps) {
  return (
    <Icon width="13px" height="12px" viewBox="0 0 13 12" fill="none" {...props}>
      <path
        d="M4.5 6H6.5H4.5ZM6.5 6H8.5H6.5ZM6.5 6V8V6ZM6.5 6V4V6ZM11.5 6C11.5 8.7614 9.26142 11 6.5 11C3.73858 11 1.5 8.7614 1.5 6C1.5 3.23858 3.73858 1 6.5 1C9.26142 1 11.5 3.23858 11.5 6Z"
        fill="#E9FFF4"
      />

      <path
        d="M4.5 6H6.5M6.5 6H8.5M6.5 6V8M6.5 6V4M11.5 6C11.5 8.7614 9.26142 11 6.5 11C3.73858 11 1.5 8.7614 1.5 6C1.5 3.23858 3.73858 1 6.5 1C9.26142 1 11.5 3.23858 11.5 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
