import {Icon, IconProps} from '@chakra-ui/react';

export interface BatteryIconProps extends IconProps {
  charge?: 'empty' | 'low' | 'medium' | 'full';
}

export function BatteryIcon({charge = 'full', ...props}: BatteryIconProps) {
  return (
    <Icon width="20px" height="21px" viewBox="0 0 20 21" fill="none" color="#0A0C10" {...props}>
      <path
        d="M15.8334 10.8432C15.8334 15.9167 15.8334 16.3334 8.75002 16.3334C1.66669 16.3334 1.66669 15.9167 1.66669 10.5001C1.66669 5.08341 1.66669 4.66675 8.75002 4.66675C15.8334 4.66675 15.8334 5.08342 15.8334 10.8432Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {charge !== 'empty' && (
        <path
          d="M5.20831 8C5.20831 8 5.83331 8.9615 5.83331 10.5C5.83331 12.0385 5.20831 13 5.20831 13"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}

      {(charge === 'full' || charge === 'medium') && (
        <path
          d="M8.54169 8C8.54169 8 9.16669 8.9615 9.16669 10.5C9.16669 12.0385 8.54169 13 8.54169 13"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}

      {charge === 'full' && (
        <path
          d="M11.875 8C11.875 8 12.5 8.9615 12.5 10.5C12.5 12.0385 11.875 13 11.875 13"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}

      <path
        d="M15.8333 8C17.9166 8.41667 18.3333 8 18.3333 10.5C18.3333 13 17.9166 12.5833 15.8333 13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
