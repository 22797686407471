import {Icon, IconProps} from '@chakra-ui/react';

export function CoinsIcon2({fill = 'green.400', ...rest}: IconProps) {
  return (
    <Icon width="47px" height="44px" viewBox="0 0 47 44" fill="none" {...rest}>
      <path
        d="M5.14307 23.5869V31.9251C5.14307 36.0942 12.1343 40.2633 23.3203 40.2633C34.5062 40.2633 41.4975 36.0942 41.4975 31.9251V23.5869"
        fill="#5AC57B"
      />
      <path
        d="M5.14307 23.5869V31.9251C5.14307 36.0942 12.1343 40.2633 23.3203 40.2633C34.5062 40.2633 41.4975 36.0942 41.4975 31.9251V23.5869"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3203 31.9255C33.3592 31.9255 41.4974 28.1924 41.4974 23.5873C41.4974 18.9822 33.3592 15.249 23.3203 15.249C13.2813 15.249 5.14307 18.9822 5.14307 23.5873C5.14307 28.1924 13.2813 31.9255 23.3203 31.9255Z"
        fill="#5AC57B"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3203 31.9258V40.264"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5068 30.207V38.545"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1348 30.207V38.545"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9.86182V18.2C1 22.3691 7.99123 26.5382 19.1772 26.5382C30.3632 26.5382 37.3544 22.3691 37.3544 18.2V9.86182"
        fill="#5AC57B"
      />
      <path
        d="M1 9.86182V18.2C1 22.3691 7.99123 26.5382 19.1772 26.5382C30.3632 26.5382 37.3544 22.3691 37.3544 18.2V9.86182"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1772 18.1998C29.2162 18.1998 37.3544 14.4667 37.3544 9.86163C37.3544 5.25657 29.2162 1.52344 19.1772 1.52344C9.1382 1.52344 1 5.25657 1 9.86163C1 14.4667 9.1382 18.1998 19.1772 18.1998Z"
        fill="#5AC57B"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1772 18.1997V26.538"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3638 16.4814V24.8197"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99219 16.4814V24.8197"
        stroke="#5AC57B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.18701 25.5962V33.9344C9.18701 38.1035 16.1782 42.2726 27.3642 42.2726C38.5502 42.2726 45.5414 38.1035 45.5414 33.9344V25.5962"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.18701 25.5963C9.18701 30.2014 17.3252 33.9346 27.3642 33.9346C37.4032 33.9346 45.5414 30.2014 45.5414 25.5963C45.5414 23.727 44.2005 22.0014 41.9359 20.6108"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3643 33.9346V42.2726"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5508 32.2158V40.5541"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1787 32.2158V40.5541"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04297 11.8711V20.2093C5.04297 24.3784 12.0342 28.5475 23.2202 28.5475C34.4061 28.5475 41.3974 24.3784 41.3974 20.2093V11.8711"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2202 20.209C33.2592 20.209 41.3974 16.4759 41.3974 11.8708C41.3974 7.26582 33.2592 3.53271 23.2202 3.53271C13.1812 3.53271 5.04297 7.26582 5.04297 11.8708C5.04297 16.4759 13.1812 20.209 23.2202 20.209Z"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2202 20.209V28.5472"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4072 18.4907V26.8289"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0347 18.4907V26.8289"
        stroke="#1B1B1B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
