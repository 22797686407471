import {Icon, IconProps} from '@chakra-ui/react';

export function ListDoneIcon(props: IconProps) {
  return (
    <Icon width="37px" height="50px" viewBox="0 0 37 50" fill="none" {...props}>
      <path
        d="M35 49C35.3978 49 35.7794 48.842 36.0607 48.5607C36.342 48.2794 36.5 47.8978 36.5 47.5V7C36.5 6.60218 36.342 6.22064 36.0607 5.93934C35.7794 5.65804 35.3978 5.5 35 5.5H27.677L25.8418 1.82875C25.7171 1.57965 25.5256 1.37019 25.2886 1.22381C25.0516 1.07744 24.7785 0.99994 24.5 1H12.5C12.2215 0.99994 11.9484 1.07744 11.7114 1.22381C11.4744 1.37019 11.2829 1.57965 11.1583 1.82875L9.323 5.5H2C1.60218 5.5 1.22064 5.65804 0.93934 5.93934C0.658036 6.22064 0.5 6.60218 0.5 7V47.5C0.5 47.8978 0.658036 48.2794 0.93934 48.5607C1.22064 48.842 1.60218 49 2 49H35ZM13.427 4H23.5723L26.5723 10H10.427L13.427 4ZM3.5 8.5H7.823L6.65825 10.8287C6.54402 11.0575 6.49012 11.3116 6.50167 11.567C6.51322 11.8224 6.58983 12.0707 6.72423 12.2882C6.85864 12.5057 7.04638 12.6852 7.26966 12.8098C7.49293 12.9344 7.74432 12.9998 8 13H29.015C29.2878 13 29.5554 12.9256 29.7891 12.7849C30.0227 12.6441 30.2136 12.4423 30.3411 12.2012C30.4686 11.96 30.5279 11.6887 30.5127 11.4163C30.4975 11.144 30.4083 10.8809 30.2547 10.6555L29.177 8.5H33.5V46H3.5V8.5Z"
        fill="#5AC57B"
        stroke="#5AC57B"
      />
      <path
        d="M23.4394 22.4395L16.9999 28.8783L13.5604 25.4395C13.422 25.2962 13.2565 25.182 13.0735 25.1033C12.8905 25.0247 12.6936 24.9834 12.4945 24.9816C12.2953 24.9799 12.0978 25.0178 11.9134 25.0933C11.7291 25.1687 11.5616 25.2801 11.4208 25.4209C11.2799 25.5617 11.1686 25.7292 11.0931 25.9136C11.0177 26.0979 10.9798 26.2954 10.9815 26.4946C10.9832 26.6938 11.0246 26.8906 11.1032 27.0736C11.1818 27.2566 11.2961 27.4221 11.4394 27.5605L15.9394 32.0605C16.2326 32.3538 16.6159 32.5 16.9999 32.5C17.3839 32.5 17.7671 32.3538 18.0604 32.0605L25.5604 24.5605C25.7036 24.4221 25.8179 24.2566 25.8965 24.0736C25.9751 23.8906 26.0165 23.6938 26.0183 23.4946C26.02 23.2954 25.982 23.0979 25.9066 22.9136C25.8312 22.7292 25.7198 22.5617 25.579 22.4209C25.4381 22.2801 25.2707 22.1687 25.0863 22.0933C24.902 22.0178 24.7044 21.9799 24.5053 21.9816C24.3061 21.9834 24.1093 22.0247 23.9263 22.1033C23.7433 22.182 23.5777 22.2962 23.4394 22.4395V22.4395Z"
        fill="#5AC57B"
        stroke="#5AC57B"
      />
    </Icon>
  );
}
