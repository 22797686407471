import {Icon, IconProps} from '@chakra-ui/react';

export function BallIcon2(props: IconProps) {
  return (
    <Icon width="21px" height="21px" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M20 10.5C20 5.2617 15.7383 1 10.5 1C7.21015 1 4.30632 2.6815 2.60075 5.2294C2.59093 5.24428 2.57922 5.25758 2.57225 5.27373C1.57982 6.77442 1 8.57023 1 10.5C1 15.0638 4.23507 18.885 8.53223 19.7938C8.5335 19.7942 8.53445 19.7945 8.53572 19.7948C9.16968 19.9284 9.82645 20 10.5 20C11.4592 20 12.3848 19.8559 13.2582 19.5902C13.2727 19.5868 13.2867 19.5836 13.3006 19.5782C17.1763 18.3802 20 14.7639 20 10.5ZM13.6987 18.7672L14.5581 16.2367L17.2235 16.2706C16.2788 17.3694 15.0714 18.2343 13.6987 18.7672ZM6.98595 16.5848L8.06927 19.0253C6.64585 18.6187 5.3681 17.8657 4.33323 16.8621L6.98595 16.5848ZM6.96727 15.9498L3.78667 16.2823C2.56528 14.8665 1.78375 13.0628 1.65455 11.0827L4.20562 9.0522L7.23232 10.0611L8.3321 13.4456L6.96727 15.9498ZM2.79423 6.12208L3.7284 8.62248L1.63872 10.2859C1.67482 8.7748 2.09028 7.35645 2.79423 6.12208ZM8.86885 13.0476L7.86058 9.94488L10.5 8.02715L13.1394 9.94488L12.1312 13.0476H8.86885ZM13.7677 10.0614L16.9226 9.00977L19.3578 10.8433C19.2894 12.6286 18.6909 14.2794 17.7153 15.6439L14.5454 15.6037L12.7113 13.3117L13.7677 10.0614ZM19.3553 10.0484L17.22 8.44198L18.088 5.9172C18.8258 7.13447 19.2793 8.54237 19.3553 10.0484ZM17.6449 5.25727L16.5464 8.45275C16.5445 8.45782 16.5452 8.4632 16.5436 8.46827L13.5555 9.46418L10.8167 7.47425V4.02702L13.425 2.13113C15.1278 2.72837 16.5946 3.82973 17.6449 5.25727ZM12.6638 1.90155L10.5 3.47412L8.33622 1.90123C9.02908 1.72707 9.75362 1.63333 10.5 1.63333C11.2464 1.63333 11.9709 1.72707 12.6638 1.90155ZM7.57495 2.13145L10.1833 4.02702V7.47425L7.44448 9.46418L4.3326 8.42678L3.21888 5.44853C4.27655 3.92853 5.79687 2.75465 7.57495 2.13145ZM8.84383 19.2093L7.52777 16.2443L8.9249 13.6809H12.1957L13.9938 15.928L12.9431 19.0218C12.1666 19.2447 11.3474 19.3667 10.5 19.3667C9.9338 19.3667 9.3809 19.3112 8.84383 19.2093Z"
        fill="#101010"
        stroke="#101010"
        strokeWidth="0.2"
      />
    </Icon>
  );
}
