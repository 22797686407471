import {Icon} from '@chakra-ui/react';

export function IDIcon() {
  return (
    <Icon width="20px" height="15px" viewBox="0 0 20 15" fill="none">
      <line x1="3.4" y1="4.6" x2="16.6" y2="4.6" stroke="#101010" strokeWidth="0.8" strokeLinecap="round" />
      <line x1="3.4" y1="7.6" x2="16.6" y2="7.6" stroke="#101010" strokeWidth="0.8" strokeLinecap="round" />
      <line x1="3.4" y1="10.6" x2="16.6" y2="10.6" stroke="#101010" strokeWidth="0.8" strokeLinecap="round" />
      <rect x="0.4" y="0.4" width="19.2" height="14.2" rx="0.6" stroke="#101010" strokeWidth="0.8" />
    </Icon>
  );
}
