import {Icon, IconProps} from '@chakra-ui/react';

export function NotFoundIcon(props: IconProps) {
  return (
    <Icon width="485" height="319" viewBox="0 0 485 319" fill="none" {...props}>
      <path
        d="M172.661 182.24V214.623C172.661 215.485 172.144 216.145 171.468 216.145H157.587C156.911 216.145 156.394 216.805 156.394 217.668V247.563C156.394 248.426 155.877 249.086 155.201 249.086H127.44C126.764 249.086 126.247 248.426 126.247 247.563V217.668C126.247 216.805 125.73 216.145 125.054 216.145H55.6123C54.9362 216.145 54.4192 215.485 54.4192 214.623V181.073C54.4192 180.819 54.459 180.616 54.5385 180.362L104.611 56.1119C104.81 55.6043 105.208 55.2998 105.685 55.2998H135.116C135.991 55.2998 136.588 56.5179 136.19 57.5331L87.6286 178.535C87.2309 179.55 87.7877 180.768 88.7025 180.768H125.054C125.73 180.768 126.247 180.109 126.247 179.246V136.357C126.247 135.494 126.764 134.834 127.44 134.834H155.201C155.877 134.834 156.394 135.494 156.394 136.357V179.246C156.394 180.109 156.911 180.768 157.587 180.768H171.468C172.104 180.718 172.661 181.428 172.661 182.24Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.661 182.24V214.623C172.661 215.485 172.144 216.145 171.468 216.145H157.587C156.911 216.145 156.394 216.805 156.394 217.668V247.563C156.394 248.426 155.877 249.086 155.201 249.086H127.44C126.764 249.086 126.247 248.426 126.247 247.563V217.668C126.247 216.805 125.73 216.145 125.054 216.145H55.6123C54.9362 216.145 54.4192 215.485 54.4192 214.623V181.073C54.4192 180.819 54.459 180.616 54.5385 180.362L104.611 56.1119C104.81 55.6043 105.208 55.2998 105.685 55.2998H135.116C135.991 55.2998 136.588 56.5179 136.19 57.5331L87.6286 178.535C87.2309 179.55 87.7877 180.768 88.7025 180.768H125.054C125.73 180.768 126.247 180.109 126.247 179.246V136.357C126.247 135.494 126.764 134.834 127.44 134.834H155.201C155.877 134.834 156.394 135.494 156.394 136.357V179.246C156.394 180.109 156.911 180.768 157.587 180.768H171.468C172.104 180.718 172.661 181.428 172.661 182.24Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M443.785 182.24V214.623C443.785 215.485 443.268 216.145 442.592 216.145H428.712C428.036 216.145 427.519 216.805 427.519 217.668V247.563C427.519 248.426 427.002 249.086 426.325 249.086H398.565C397.889 249.086 397.372 248.426 397.372 247.563V217.668C397.372 216.805 396.855 216.145 396.178 216.145H326.777C326.101 216.145 325.583 215.485 325.583 214.623V181.073C325.583 180.819 325.623 180.616 325.703 180.362L375.775 56.1119C375.974 55.6043 376.372 55.2998 376.849 55.2998H406.28C407.155 55.2998 407.752 56.5179 407.354 57.5331L358.793 178.535C358.395 179.55 358.952 180.768 359.867 180.768H396.218C396.894 180.768 397.411 180.109 397.411 179.246V136.357C397.411 135.494 397.928 134.834 398.604 134.834H426.365C427.041 134.834 427.558 135.494 427.558 136.357V179.246C427.558 180.109 428.075 180.768 428.752 180.768H442.632C443.268 180.718 443.785 181.428 443.785 182.24Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M443.785 182.24V214.623C443.785 215.485 443.268 216.145 442.592 216.145H428.712C428.036 216.145 427.519 216.805 427.519 217.668V247.563C427.519 248.426 427.002 249.086 426.325 249.086H398.565C397.889 249.086 397.372 248.426 397.372 247.563V217.668C397.372 216.805 396.855 216.145 396.178 216.145H326.777C326.101 216.145 325.583 215.485 325.583 214.623V181.073C325.583 180.819 325.623 180.616 325.703 180.362L375.775 56.1119C375.974 55.6043 376.372 55.2998 376.849 55.2998H406.28C407.155 55.2998 407.752 56.5179 407.354 57.5331L358.793 178.535C358.395 179.55 358.952 180.768 359.867 180.768H396.218C396.894 180.768 397.411 180.109 397.411 179.246V136.357C397.411 135.494 397.928 134.834 398.604 134.834H426.365C427.041 134.834 427.558 135.494 427.558 136.357V179.246C427.558 180.109 428.075 180.768 428.752 180.768H442.632C443.268 180.718 443.785 181.428 443.785 182.24Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M248.346 249.085C229.415 249.085 214.54 239.898 203.762 221.524C192.984 203.151 187.615 178.737 187.615 148.284C187.615 117.83 192.984 93.4165 203.762 75.0429C214.54 56.6692 229.375 47.4824 248.346 47.4824C267.437 47.4824 282.311 56.6692 293.05 75.0429C303.748 93.4165 309.118 117.83 309.118 148.284C309.118 178.737 303.748 203.151 293.05 221.524C282.311 239.949 267.437 249.085 248.346 249.085ZM225.358 195.537C230.568 206.348 238.244 211.729 248.346 211.729C258.448 211.729 266.164 206.298 271.454 195.385C276.743 184.473 279.368 168.789 279.368 148.334C279.368 127.677 276.743 111.892 271.454 100.979C266.164 90.0666 258.488 84.6357 248.346 84.6357C238.204 84.6357 230.568 90.0666 225.358 100.979C220.148 111.892 217.562 127.626 217.562 148.334C217.523 168.992 220.148 184.726 225.358 195.537Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248.346 249.085C229.415 249.085 214.54 239.898 203.762 221.524C192.984 203.151 187.615 178.737 187.615 148.284C187.615 117.83 192.984 93.4165 203.762 75.0429C214.54 56.6692 229.375 47.4824 248.346 47.4824C267.437 47.4824 282.311 56.6692 293.05 75.0429C303.748 93.4165 309.118 117.83 309.118 148.284C309.118 178.737 303.748 203.151 293.05 221.524C282.311 239.949 267.437 249.085 248.346 249.085ZM225.358 195.537C230.568 206.348 238.244 211.729 248.346 211.729C258.448 211.729 266.164 206.298 271.454 195.385C276.743 184.473 279.368 168.789 279.368 148.334C279.368 127.677 276.743 111.892 271.454 100.979C266.164 90.0666 258.488 84.6357 248.346 84.6357C238.204 84.6357 230.568 90.0666 225.358 100.979C220.148 111.892 217.562 127.626 217.562 148.334C217.523 168.992 220.148 184.726 225.358 195.537Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.991 182.24V214.623C163.991 215.485 163.474 216.145 162.797 216.145H148.917C148.241 216.145 147.724 216.805 147.724 217.668V247.563C147.724 248.426 147.207 249.086 146.531 249.086H118.77C118.094 249.086 117.577 248.426 117.577 247.563V217.668C117.577 216.805 117.06 216.145 116.384 216.145H46.9422C46.2661 216.145 45.749 215.485 45.749 214.623V181.073C45.749 180.819 45.7888 180.616 45.8683 180.362L95.941 56.1119C96.1399 55.6043 96.5376 55.2998 97.0149 55.2998H126.446C127.321 55.2998 127.918 56.5179 127.52 57.5331L78.9585 178.535C78.5607 179.55 79.1175 180.768 80.0323 180.768H116.384C117.06 180.768 117.577 180.109 117.577 179.246V136.357C117.577 135.494 118.094 134.834 118.77 134.834H146.531C147.207 134.834 147.724 135.494 147.724 136.357V179.246C147.724 180.109 148.241 180.768 148.917 180.768H162.797C163.434 180.718 163.991 181.428 163.991 182.24Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.991 182.24V214.623C163.991 215.485 163.474 216.145 162.797 216.145H148.917C148.241 216.145 147.724 216.805 147.724 217.668V247.563C147.724 248.426 147.207 249.086 146.531 249.086H118.77C118.094 249.086 117.577 248.426 117.577 247.563V217.668C117.577 216.805 117.06 216.145 116.384 216.145H46.9422C46.2661 216.145 45.749 215.485 45.749 214.623V181.073C45.749 180.819 45.7888 180.616 45.8683 180.362L95.941 56.1119C96.1399 55.6043 96.5376 55.2998 97.0149 55.2998H126.446C127.321 55.2998 127.918 56.5179 127.52 57.5331L78.9585 178.535C78.5607 179.55 79.1176 180.768 80.0323 180.768H116.384C117.06 180.768 117.577 180.109 117.577 179.246V136.357C117.577 135.494 118.094 134.834 118.77 134.834H146.531C147.207 134.834 147.724 135.494 147.724 136.357V179.246C147.724 180.109 148.241 180.768 148.917 180.768H162.797C163.434 180.718 163.991 181.428 163.991 182.24Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M435.115 182.24V214.623C435.115 215.485 434.598 216.145 433.922 216.145H420.041C419.365 216.145 418.848 216.805 418.848 217.668V247.563C418.848 248.426 418.331 249.086 417.655 249.086H389.894C389.218 249.086 388.701 248.426 388.701 247.563V217.668C388.701 216.805 388.184 216.145 387.508 216.145H318.106C317.43 216.145 316.913 215.485 316.913 214.623V181.073C316.913 180.819 316.953 180.616 317.032 180.362L367.105 56.1119C367.304 55.6043 367.702 55.2998 368.179 55.2998H397.61C398.485 55.2998 399.082 56.5179 398.684 57.5331L350.123 178.535C349.725 179.55 350.282 180.768 351.196 180.768H387.548C388.224 180.768 388.741 180.109 388.741 179.246V136.357C388.741 135.494 389.258 134.834 389.934 134.834H417.695C418.371 134.834 418.888 135.494 418.888 136.357V179.246C418.888 180.109 419.405 180.768 420.081 180.768H433.961C434.598 180.718 435.115 181.428 435.115 182.24Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M435.115 182.24V214.623C435.115 215.485 434.598 216.145 433.922 216.145H420.041C419.365 216.145 418.848 216.805 418.848 217.668V247.563C418.848 248.426 418.331 249.086 417.655 249.086H389.894C389.218 249.086 388.701 248.426 388.701 247.563V217.668C388.701 216.805 388.184 216.145 387.508 216.145H318.106C317.43 216.145 316.913 215.485 316.913 214.623V181.073C316.913 180.819 316.953 180.616 317.032 180.362L367.105 56.1119C367.304 55.6043 367.702 55.2998 368.179 55.2998H397.61C398.485 55.2998 399.082 56.5179 398.684 57.5331L350.123 178.535C349.725 179.55 350.282 180.768 351.196 180.768H387.548C388.224 180.768 388.741 180.109 388.741 179.246V136.357C388.741 135.494 389.258 134.834 389.934 134.834H417.695C418.371 134.834 418.888 135.494 418.888 136.357V179.246C418.888 180.109 419.405 180.768 420.081 180.768H433.961C434.598 180.718 435.115 181.428 435.115 182.24Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.7709 78.1393C55.7709 83.3672 52.4699 87.5799 48.3734 87.5799C44.2769 87.5799 40.9758 83.3672 40.9758 78.1393C40.9758 72.9115 44.2769 68.6987 48.3734 68.6987C52.4699 68.6987 55.7709 72.9622 55.7709 78.1393Z"
        fill="black"
      />
      <path
        d="M465.461 95.9538C465.461 101.182 462.16 105.394 458.063 105.394C453.967 105.394 450.666 101.182 450.666 95.9538C450.666 90.7259 453.967 86.5132 458.063 86.5132C462.12 86.5132 465.461 90.7767 465.461 95.9538Z"
        fill="black"
      />
      <path
        d="M334.969 81.9968C334.969 87.2246 331.668 91.4374 327.572 91.4374C323.475 91.4374 320.174 87.2246 320.174 81.9968C320.174 76.7689 323.475 72.5562 327.572 72.5562C331.668 72.5562 334.969 76.7689 334.969 81.9968Z"
        fill="black"
      />
      <path
        d="M160.808 106.867C160.808 110.725 158.382 113.821 155.36 113.821C152.337 113.821 149.911 110.725 149.911 106.867C149.911 103.01 152.337 99.9136 155.36 99.9136C158.343 99.9136 160.808 103.06 160.808 106.867Z"
        fill="black"
      />
      <path
        d="M66.2314 247.714C66.2314 251.419 63.8849 254.414 60.9816 254.414C58.0782 254.414 55.7317 251.419 55.7317 247.714C55.7317 244.009 58.0782 241.014 60.9816 241.014C63.8849 241.065 66.2314 244.06 66.2314 247.714Z"
        fill="black"
      />
      <path
        d="M363.725 246.751C363.725 251.979 360.423 256.192 356.327 256.192C352.231 256.192 348.929 251.979 348.929 246.751C348.929 241.523 352.231 237.311 356.327 237.311C360.384 237.311 363.725 241.523 363.725 246.751Z"
        fill="black"
      />
      <path
        d="M61.2596 16.2686C63.288 23.5266 57.4813 26.166 59.5097 33.424C61.538 40.6821 67.3447 38.0428 69.3731 45.3009C71.4014 52.559 65.5947 55.1983 67.6231 62.4564"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.957 83.6206C145.536 89.559 140.803 85.1433 137.383 91.031C133.963 96.9694 138.735 101.385 135.315 107.324C131.894 113.262 127.162 108.846 123.741 114.785"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.2019 11.8018C97.6109 15.2024 94.8666 22.2575 100.276 25.6581C105.685 29.0588 108.429 22.0037 113.838 25.4551C119.247 28.8557 116.502 35.9108 121.911 39.3115"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.507 58.1929C155.678 61.4412 153.053 68.1918 158.223 71.4402C163.393 74.6885 166.018 67.9888 171.189 71.2371C176.359 74.4855 173.734 81.2361 178.904 84.4844"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M207.103 27.1804C213.149 26.7744 213.467 34.6415 219.472 34.2355C225.518 33.8295 225.199 26.013 231.205 25.607C237.25 25.201 237.568 33.0681 243.574 32.6621"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M390.65 263.042C396.695 262.636 397.013 270.503 403.019 270.097C409.064 269.691 408.746 261.875 414.752 261.469C420.797 261.063 421.115 268.93 427.121 268.524"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M457.625 256.191C458.063 248.527 464.228 249.085 464.665 241.421C465.103 233.757 458.978 233.147 459.415 225.483C459.853 217.819 466.017 218.378 466.455 210.713"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.073 2.00537C278.732 8.14684 273.84 12.9179 277.499 19.0086C281.158 25.1501 286.05 20.379 289.709 26.5205C293.368 32.6619 288.476 37.433 292.135 43.5237"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.001 10.2277C191.752 13.9836 188.729 7.1823 183.479 10.9382C178.229 14.6942 181.252 21.5462 175.962 25.3529C170.712 29.1089 167.69 22.2568 162.44 26.0635"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M356.327 39.6661C351.077 43.4221 348.055 36.6208 342.805 40.3767C337.555 44.1327 340.577 50.9847 335.288 54.7914C330.038 58.5473 327.015 51.746 321.765 55.502"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M441.279 70.2203C436.029 73.9763 433.006 67.175 427.757 70.9309C422.507 74.6869 425.529 81.5389 420.24 85.3456C414.99 89.1015 411.967 82.2495 406.717 86.0562"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M471.585 70.3745C467.608 64.5884 472.261 59.4113 468.324 53.5743C464.347 47.7882 459.733 52.9145 455.756 47.1283C451.779 41.3422 456.432 36.1651 452.495 30.3281"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M448.041 141.635C444.143 135.747 448.837 130.671 444.939 124.784C441.041 118.896 436.348 123.972 432.451 118.135C428.553 112.247 433.246 107.171 429.348 101.284"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M451.819 176.707C456.353 171.581 460.41 177.468 464.944 172.393C469.478 167.266 465.382 161.379 469.876 156.303C474.41 151.177 478.466 157.064 483 151.989"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M417.058 23.2719C411.808 27.0279 408.785 20.1758 403.535 23.9825C398.286 27.7384 401.308 34.5905 396.019 38.3972C390.769 42.1531 387.746 35.3518 382.496 39.1078"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9758 47.584C36.7203 53.0656 32.3454 47.5332 28.1295 53.0149C23.874 58.4965 28.2489 64.0289 24.0331 69.5105C19.7775 74.9922 15.4026 69.4598 11.1868 74.9414"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4413 107.019C59.7084 111.841 55.8903 105.699 51.1575 110.521C46.4246 115.343 50.2825 121.484 45.5894 126.256C40.8566 131.077 37.0385 124.936 32.3057 129.758"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2666 188.99C22.1246 182.392 27.3745 178.331 24.2723 171.733C21.1303 165.135 15.8805 169.246 12.7385 162.648C9.59652 156.049 14.8464 151.989 11.7442 145.391"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5447 232.945C42.5676 238.731 37.954 233.554 33.9769 239.391C29.9997 245.177 34.653 250.354 30.6758 256.14C26.6986 261.926 22.0851 256.749 18.1079 262.586"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 239.898C2.47726 232.234 8.64189 232.843 9.11915 225.179C9.59641 217.514 3.47156 216.855 3.94882 209.19C4.42608 201.526 10.5907 202.135 11.068 194.471"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.935 259.643C98.407 256.547 100.873 249.339 95.3844 246.243C89.8561 243.147 87.3902 250.355 81.862 247.258C76.3337 244.162 78.7995 236.955 73.311 233.859"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.662 284.36C97.5712 288.522 94.2701 281.924 89.1793 286.035C84.0885 290.197 87.4294 296.796 82.3386 300.958C77.2478 305.12 73.9467 298.521 68.856 302.632"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.014 271.723C154.008 272.687 153.253 264.871 147.287 265.835C141.281 266.799 142.077 274.616 136.071 275.58C130.066 276.545 129.31 268.728 123.344 269.692"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.053 280.91C177.752 274.819 182.604 279.641 186.342 273.601C190.041 267.51 185.189 262.688 188.888 256.597C192.587 250.507 197.439 255.329 201.177 249.289"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.143 297.913C199.069 299.334 197.916 307.049 203.842 308.47C209.768 309.891 210.921 302.176 216.847 303.597C222.773 305.018 221.62 312.733 227.546 314.154"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M305.061 254.364C300.805 259.795 296.43 254.262 292.175 259.693C287.919 265.124 292.254 270.707 287.999 276.138C283.743 281.569 279.368 276.037 275.113 281.467"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M356.327 291.568C351.276 287.355 354.656 280.757 349.605 276.544C344.554 272.332 341.174 278.879 336.123 274.666C331.072 270.454 334.452 263.855 329.401 259.643"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.358 268.525C231.165 270.657 229.455 278.22 235.222 280.352C241.028 282.483 242.738 274.971 248.545 277.103C254.352 279.235 252.642 286.798 258.409 288.929"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.887 103.973C236.216 107.628 233.273 114.53 238.602 118.185C243.932 121.839 246.835 114.886 252.165 118.54C257.494 122.195 254.551 129.097 259.88 132.752"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M229.574 177.417C234.864 173.712 237.807 180.615 243.136 176.91C248.426 173.204 245.483 166.302 250.773 162.647C256.062 158.942 259.005 165.845 264.335 162.14"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.051 145.391C244.051 146.914 243.057 148.183 241.864 148.183C240.671 148.183 239.677 146.914 239.677 145.391C239.677 143.869 240.671 142.6 241.864 142.6C243.097 142.6 244.051 143.869 244.051 145.391Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.3727 36.5196C90.3727 38.0423 89.3784 39.3112 88.1853 39.3112C86.9921 39.3112 85.9978 38.0423 85.9978 36.5196C85.9978 34.9969 86.9921 33.728 88.1853 33.728C89.3784 33.728 90.3727 34.9969 90.3727 36.5196Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8798 103.416C22.8798 104.938 21.8855 106.207 20.6923 106.207C19.4992 106.207 18.5049 104.938 18.5049 103.416C18.5049 101.893 19.4992 100.624 20.6923 100.624C21.8855 100.624 22.8798 101.842 22.8798 103.416Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.6292 128.359C58.2643 127.048 57.946 125.327 56.9183 124.517C55.8906 123.706 54.5426 124.112 53.9075 125.424C53.2724 126.736 53.5907 128.456 54.6184 129.266C55.6462 130.077 56.9941 129.671 57.6292 128.359Z"
        stroke="black"
        strokeWidth="2.50667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4931 151.126C34.4931 152.649 33.4988 153.918 32.3056 153.918C31.1125 153.918 30.1182 152.649 30.1182 151.126C30.1182 149.603 31.1125 148.334 32.3056 148.334C33.4988 148.334 34.4931 149.603 34.4931 151.126Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3056 211.729C32.3056 213.252 31.3113 214.521 30.1181 214.521C28.925 214.521 27.9307 213.252 27.9307 211.729C27.9307 210.206 28.925 208.938 30.1181 208.938C31.3113 208.938 32.3056 210.206 32.3056 211.729Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.475 233.859C100.475 235.382 99.4807 236.651 98.2875 236.651C97.0944 236.651 96.1001 235.382 96.1001 233.859C96.1001 232.336 97.0944 231.067 98.2875 231.067C99.4807 231.067 100.475 232.286 100.475 233.859Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1859 275.631C74.1859 277.154 73.1916 278.423 71.9985 278.423C70.8053 278.423 69.811 277.154 69.811 275.631C69.811 274.108 70.8053 272.839 71.9985 272.839C73.1916 272.839 74.1859 274.108 74.1859 275.631Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.077 164.322C107.077 165.845 106.083 167.114 104.89 167.114C103.697 167.114 102.702 165.845 102.702 164.322C102.702 162.8 103.697 161.531 104.89 161.531C106.083 161.531 107.077 162.8 107.077 164.322Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.797 132.753C169.797 134.275 168.803 135.544 167.61 135.544C166.417 135.544 165.422 134.275 165.422 132.753C165.422 131.23 166.417 129.961 167.61 129.961C168.803 129.961 169.797 131.23 169.797 132.753Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.103 47.5845C186.103 49.1072 185.109 50.3761 183.916 50.3761C182.723 50.3761 181.729 49.1072 181.729 47.5845C181.729 46.0619 182.723 44.793 183.916 44.793C185.149 44.793 186.103 46.0111 186.103 47.5845Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.935 25.5567C145.935 27.0794 144.94 28.3483 143.747 28.3483C142.554 28.3483 141.56 27.0794 141.56 25.5567C141.56 24.034 142.554 22.7651 143.747 22.7651C144.98 22.7651 145.935 24.034 145.935 25.5567Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.99 243.959C177.99 245.482 176.996 246.751 175.803 246.751C174.61 246.751 173.615 245.482 173.615 243.959C173.615 242.436 174.61 241.167 175.803 241.167C176.996 241.167 177.99 242.386 177.99 243.959Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.935 288.117C145.935 289.639 144.94 290.908 143.747 290.908C142.554 290.908 141.56 289.639 141.56 288.117C141.56 286.594 142.554 285.325 143.747 285.325C144.98 285.325 145.935 286.594 145.935 288.117Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.073 265.073C275.073 266.596 274.079 267.864 272.886 267.864C271.693 267.864 270.698 266.596 270.698 265.073C270.698 263.55 271.693 262.281 272.886 262.281C274.119 262.281 275.073 263.55 275.073 265.073Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.073 314.204C275.073 315.727 274.079 316.996 272.886 316.996C271.693 316.996 270.698 315.727 270.698 314.204C270.698 312.682 271.693 311.413 272.886 311.413C274.119 311.413 275.073 312.682 275.073 314.204Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M313.453 284.36C313.453 285.883 312.458 287.152 311.265 287.152C310.072 287.152 309.078 285.883 309.078 284.36C309.078 282.838 310.072 281.569 311.265 281.569C312.498 281.569 313.453 282.787 313.453 284.36Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.868 232.031C317.868 233.554 316.874 234.822 315.681 234.822C314.487 234.822 313.493 233.554 313.493 232.031C313.493 230.508 314.487 229.239 315.681 229.239C316.874 229.239 317.868 230.508 317.868 232.031Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.852 11.8004C222.852 13.323 221.858 14.5919 220.665 14.5919C219.472 14.5919 218.478 13.323 218.478 11.8004C218.478 10.2777 219.472 9.00879 220.665 9.00879C221.858 9.00879 222.852 10.2777 222.852 11.8004Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.005 29.9215C266.005 31.4441 265.01 32.713 263.817 32.713C262.624 32.713 261.63 31.4441 261.63 29.9215C261.63 28.3988 262.624 27.1299 263.817 27.1299C265.01 27.1299 266.005 28.348 266.005 29.9215Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M384.683 284.36C384.683 285.883 383.689 287.152 382.496 287.152C381.303 287.152 380.309 285.883 380.309 284.36C380.309 282.838 381.303 281.569 382.496 281.569C383.689 281.569 384.683 282.787 384.683 284.36Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M323.913 116.918C323.913 118.44 322.919 119.709 321.726 119.709C320.533 119.709 319.538 118.44 319.538 116.918C319.538 115.395 320.533 114.126 321.726 114.126C322.959 114.126 323.913 115.395 323.913 116.918Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M429.308 43.523C429.308 45.0457 428.314 46.3146 427.121 46.3146C425.928 46.3146 424.934 45.0457 424.934 43.523C424.934 42.0003 425.928 40.7314 427.121 40.7314C428.354 40.7314 429.308 42.0003 429.308 43.523Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M376.014 28.3985C376.014 29.9212 375.02 31.1901 373.827 31.1901C372.634 31.1901 371.639 29.9212 371.639 28.3985C371.639 26.8758 372.634 25.6069 373.827 25.6069C375.02 25.5562 376.014 26.8251 376.014 28.3985Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M315.681 29.9215C315.681 31.4441 314.686 32.713 313.493 32.713C312.3 32.713 311.306 31.4441 311.306 29.9215C311.306 28.3988 312.3 27.1299 313.493 27.1299C314.686 27.1299 315.681 28.348 315.681 29.9215Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M408.906 106.207C408.906 107.73 407.911 108.999 406.718 108.999C405.525 108.999 404.531 107.73 404.531 106.207C404.531 104.684 405.525 103.416 406.718 103.416C407.911 103.416 408.906 104.684 408.906 106.207Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M443.467 241.117C443.467 242.639 442.473 243.908 441.279 243.908C440.086 243.908 439.092 242.639 439.092 241.117C439.092 239.594 440.086 238.325 441.279 238.325C442.473 238.325 443.467 239.594 443.467 241.117Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M450.228 281.569C450.228 283.092 449.234 284.361 448.04 284.361C446.847 284.361 445.853 283.092 445.853 281.569C445.853 280.046 446.847 278.777 448.04 278.777C449.273 278.727 450.228 279.995 450.228 281.569Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M469.597 129.707C469.597 131.23 468.603 132.499 467.41 132.499C466.216 132.499 465.222 131.23 465.222 129.707C465.222 128.184 466.216 126.916 467.41 126.916C468.643 126.916 469.597 128.184 469.597 129.707Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M483 195.437C483 196.959 482.006 198.228 480.812 198.228C479.619 198.228 478.625 196.959 478.625 195.437C478.625 193.914 479.619 192.645 480.812 192.645C482.006 192.645 483 193.863 483 195.437Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.552 281.111C212.552 282.634 211.557 283.903 210.364 283.903C209.171 283.903 208.177 282.634 208.177 281.111C208.177 279.589 209.171 278.32 210.364 278.32C211.557 278.32 212.552 279.589 212.552 281.111Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M239.676 249.085C220.745 249.085 205.87 239.898 195.092 221.524C184.314 203.151 178.945 178.737 178.945 148.284C178.945 117.83 184.314 93.4165 195.092 75.0429C205.87 56.6692 220.705 47.4824 239.676 47.4824C258.767 47.4824 273.641 56.6692 284.38 75.0429C295.078 93.4165 300.447 117.83 300.447 148.284C300.447 178.737 295.078 203.151 284.38 221.524C273.681 239.949 258.767 249.085 239.676 249.085ZM216.688 195.537C221.898 206.348 229.574 211.729 239.676 211.729C249.778 211.729 257.494 206.298 262.783 195.385C268.073 184.473 270.698 168.789 270.698 148.334C270.698 127.677 268.073 111.892 262.783 100.979C257.494 90.0666 249.818 84.6357 239.676 84.6357C229.534 84.6357 221.898 90.0666 216.688 100.979C211.478 111.892 208.893 127.626 208.893 148.334C208.893 168.992 211.478 184.726 216.688 195.537Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.676 249.085C220.745 249.085 205.87 239.898 195.092 221.524C184.314 203.151 178.945 178.737 178.945 148.284C178.945 117.83 184.314 93.4165 195.092 75.0429C205.87 56.6692 220.705 47.4824 239.676 47.4824C258.767 47.4824 273.641 56.6692 284.38 75.0429C295.078 93.4165 300.447 117.83 300.447 148.284C300.447 178.737 295.078 203.151 284.38 221.524C273.681 239.949 258.767 249.085 239.676 249.085ZM216.688 195.537C221.898 206.348 229.574 211.729 239.676 211.729C249.778 211.729 257.494 206.298 262.783 195.385C268.073 184.473 270.698 168.789 270.698 148.334C270.698 127.677 268.073 111.892 262.783 100.979C257.494 90.0666 249.818 84.6357 239.676 84.6357C229.534 84.6357 221.898 90.0666 216.688 100.979C211.478 111.892 208.893 127.626 208.893 148.334C208.893 168.992 211.478 184.726 216.688 195.537Z"
        stroke="black"
        strokeWidth="2.50661"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
