import {Icon, IconProps} from '@chakra-ui/react';

export function YesIcon(props: IconProps) {
  return (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#5AC57B" stroke="#5AC57B" />
      <path
        d="M4.34188 8.87526L5.90881 10.7227L10.7437 5.58179"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
