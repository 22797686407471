import {Icon, IconProps} from '@chakra-ui/react';

export function BookIcon(props: IconProps) {
  return (
    <Icon width="16px" height="18px" viewBox="0 0 16 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.75C0 2.26472 2.01472 0.25 4.5 0.25H14.5C15.3284 0.25 16 0.92157 16 1.75V16.75C16 17.3023 15.5523 17.75 15 17.75H3.5C1.73676 17.75 0.27806 16.4461 0.03544 14.75H0V4.75ZM14.5 12.25H3.5C2.39543 12.25 1.5 13.1454 1.5 14.25C1.5 15.3546 2.39543 16.25 3.5 16.25H14.5V12.25ZM4.25 4.75C4.25 4.33579 4.58579 4 5 4H12C12.4142 4 12.75 4.33579 12.75 4.75C12.75 5.16421 12.4142 5.5 12 5.5H5C4.58579 5.5 4.25 5.16421 4.25 4.75ZM5 7C4.58579 7 4.25 7.3358 4.25 7.75C4.25 8.1642 4.58579 8.5 5 8.5H10C10.4142 8.5 10.75 8.1642 10.75 7.75C10.75 7.3358 10.4142 7 10 7H5Z"
        fill="#2A2E3A"
      />
    </Icon>
  );
}
