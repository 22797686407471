import {Icon, IconProps} from '@chakra-ui/react';

export function WalletIcon(props: IconProps) {
  return (
    <Icon width="25px" height="24px" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M21.5 12C21.5 18.5 19.5 20 12.5 20C5 20 3.5 18 3.5 12C3.5 5.5 5.5 4 12.5 4C19.5 4 21.5 5.5 21.5 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M21 16C19 16 16 16 16 12.5C16 9 19 9 21 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M19 13C19.2761 13 19.5 12.7761 19.5 12.5C19.5 12.2239 19.2761 12 19 12C18.7239 12 18.5 12.2239 18.5 12.5C18.5 12.7761 18.7239 13 19 13Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </Icon>
  );
}
