import {Icon, IconProps} from '@chakra-ui/react';

export function TagIcon(props: IconProps) {
  return (
    <Icon width="14px" height="15px" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        d="M10.1314 10.595C7.54028 13.1749 7.11847 12.7549 2.41834 8.01514C1.15292 6.75526 1.99653 3.33541 2.41834 2.91544C2.84014 2.49546 6.27486 1.65551 7.54028 2.91544C12.2404 7.5952 12.6622 8.01514 10.1314 10.595Z"
        stroke="#6C6C6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M4.37498 5.16659C4.53606 5.16659 4.66665 5.036 4.66665 4.87492C4.66665 4.71384 4.53606 4.58325 4.37498 4.58325C4.2139 4.58325 4.08331 4.71384 4.08331 4.87492C4.08331 5.036 4.2139 5.16659 4.37498 5.16659Z"
        fill="#6C6C6C"
        stroke="#6C6C6C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
