import {Icon, IconProps} from '@chakra-ui/react';

export function OfflineIcon(props: IconProps) {
  return (
    <Icon width="523" height="422" viewBox="0 0 523 422" fill="none" {...props}>
      <path
        d="M123.644 172.622C123.644 172.622 111.927 151.171 91.8121 141.088C71.6293 131.006 73.1194 128.502 66.6853 113.547C63.4343 105.969 64.3825 92.5026 64.7889 87.6305C64.9921 85.1268 66.6175 84.7208 67.5657 85.8035C69.2589 87.5628 70.478 92.0289 70.9521 97.3071C71.7648 107.525 71.6294 108.946 78.6053 116.254C78.6053 116.254 76.0994 104.209 75.8284 98.3221C75.693 95.8184 76.8444 88.3749 78.8762 82.5554C79.1471 81.7434 79.8244 79.781 80.2984 79.0367C80.9757 77.8863 82.7366 77.5479 83.4139 79.2397C84.5653 82.1494 82.4657 92.1643 83.0075 98.8634C83.3462 103.194 86.3939 111.856 88.4257 114.224C88.4257 114.224 87.5453 111.923 87.7484 107.254C87.8839 102.653 90.8639 79.7133 94.5889 78.2923C98.3139 76.8713 97.5012 84.1794 97.5012 84.1794C94.9275 107.593 99.7362 113.412 99.7362 113.412C99.7362 113.412 99.6685 109.42 100.075 102.112C100.549 94.871 103.868 80.1193 106.78 87.2922C109.692 94.465 100.684 119.299 117.481 122.75C135.767 126.472 136.444 150.088 136.444 150.088"
        fill="white"
      />
      <path
        d="M123.644 172.622C123.644 172.622 111.927 151.171 91.8121 141.088C71.6293 131.006 73.1194 128.502 66.6853 113.547C63.4343 105.969 64.3825 92.5026 64.7889 87.6305C64.9921 85.1268 66.6175 84.7208 67.5657 85.8035C69.2589 87.5629 70.478 92.0289 70.9521 97.3071C71.7648 107.525 71.6294 108.946 78.6053 116.254C78.6053 116.254 76.0994 104.209 75.8284 98.3221C75.693 95.8184 76.8444 88.3749 78.8762 82.5554C79.1471 81.7434 79.8244 79.781 80.2984 79.0367C80.9757 77.8863 82.7366 77.5479 83.4139 79.2397C84.5653 82.1494 82.4657 92.1643 83.0075 98.8634C83.3462 103.194 86.3939 111.856 88.4257 114.224C88.4257 114.224 87.5453 111.923 87.7484 107.254C87.8839 102.653 90.8639 79.7133 94.5889 78.2923C98.3139 76.8713 97.5012 84.1794 97.5012 84.1794C94.9275 107.593 99.7362 113.412 99.7362 113.412C99.7362 113.412 99.6685 109.42 100.075 102.112C100.549 94.871 103.868 80.1193 106.78 87.2922C109.692 94.465 100.684 119.299 117.481 122.75C135.767 126.472 136.444 150.088 136.444 150.088"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M370.916 70.8491C376.402 79.5107 367.53 85.1271 373.016 93.7887C378.502 102.45 387.374 96.8337 392.86 105.563C398.346 114.224 389.474 119.841 394.959 128.502"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M356.22 3.24782C347.076 7.9846 342.268 -1.35362 333.125 3.38316C323.981 8.11993 328.79 17.3905 319.715 22.1273C310.571 26.864 305.763 17.5258 296.62 22.2626"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.1871 223.711C71.968 213.493 82.3303 212.275 81.1112 202.057C79.8921 191.839 69.4621 193.125 68.243 182.907C67.0239 172.689 77.3862 171.471 76.1671 161.253"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.957 130.598C189.678 123.764 196.586 131.614 204.307 124.847C212.028 118.012 205.052 110.163 212.773 103.396C220.494 96.5614 227.402 104.411 235.123 97.644"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M424.489 126.135C432.21 119.3 439.118 127.15 446.839 120.383C454.56 113.548 447.584 105.699 455.305 98.932C463.025 92.0975 469.934 99.947 477.655 93.1802"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M467.834 311.815C475.555 304.981 482.463 312.83 490.184 306.063C497.905 299.229 490.929 291.379 498.65 284.613C506.371 277.778 513.279 285.628 521 278.861"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M346.941 403.574C357.235 404.047 356.694 414.536 366.988 415.01C377.283 415.483 377.757 404.995 387.984 405.468C398.278 405.942 397.804 416.431 408.031 416.837"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M448.193 185.411C458.284 187.171 456.524 197.524 466.615 199.283C476.706 201.042 478.535 190.757 488.694 192.516C498.785 194.276 497.024 204.629 507.116 206.388"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.2385 39.9907C99.5331 40.0584 99.4653 50.547 109.76 50.547C120.054 50.6146 120.122 40.1261 130.349 40.1937C140.644 40.2614 140.576 50.75 150.87 50.75"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.8603 289.011C70.2748 282.109 77.3862 274.327 69.8007 267.425C62.2153 260.522 55.1716 268.237 47.5862 261.267C40.0007 254.365 47.1121 246.583 39.5266 239.681"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.851 382.8C109.557 382.394 109.963 371.973 99.6686 371.567C89.374 371.161 89.0354 381.649 78.7408 381.311C68.4463 380.905 68.8527 370.484 58.5581 370.078"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M235.055 61.3742C228.147 53.7954 235.936 46.7579 229.028 39.1114C222.12 31.5325 214.331 38.57 207.423 30.9235C200.515 23.3447 208.303 16.3072 201.395 8.66064"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.254 23.4801C256.254 25.5101 254.561 27.2018 252.529 27.2018C250.497 27.2018 248.804 25.5101 248.804 23.4801C248.804 21.45 250.497 19.7583 252.529 19.7583C254.628 19.7583 256.254 21.3823 256.254 23.4801Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.1109 240.222C98.1109 242.252 96.4177 243.944 94.3859 243.944C92.3541 243.944 90.6609 242.252 90.6609 240.222C90.6609 238.192 92.3541 236.5 94.3859 236.5C96.4177 236.5 98.1109 238.192 98.1109 240.222Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.32 331.777C175.32 333.807 173.627 335.499 171.595 335.499C169.563 335.499 167.87 333.807 167.87 331.777C167.87 329.747 169.563 328.056 171.595 328.056C173.627 328.056 175.32 329.747 175.32 331.777Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M488.288 253.959C488.288 255.989 486.595 257.68 484.563 257.68C482.531 257.68 480.838 255.989 480.838 253.959C480.838 251.929 482.531 250.237 484.563 250.237C486.595 250.237 488.288 251.861 488.288 253.959Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M458.555 363.785C458.555 365.815 456.862 367.507 454.83 367.507C452.799 367.507 451.105 365.815 451.105 363.785C451.105 361.755 452.799 360.063 454.83 360.063C456.862 360.063 458.555 361.755 458.555 363.785Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M356.897 39.9927C356.897 42.0228 355.204 43.7145 353.172 43.7145C351.14 43.7145 349.447 42.0228 349.447 39.9927C349.447 37.9627 351.14 36.271 353.172 36.271C355.204 36.271 356.897 37.895 356.897 39.9927Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M416.226 78.1568C416.226 80.1869 414.533 81.8786 412.501 81.8786C410.469 81.8786 408.776 80.1869 408.776 78.1568C408.776 76.1268 410.469 74.4351 412.501 74.4351C414.533 74.3674 416.226 76.0591 416.226 78.1568Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.161 154.014C463.161 156.044 461.468 157.736 459.436 157.736C457.404 157.736 455.711 156.044 455.711 154.014C455.711 151.984 457.404 150.292 459.436 150.292C461.468 150.292 463.161 151.984 463.161 154.014Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.5931 335.5C90.5931 337.53 88.8999 339.222 86.8681 339.222C84.8362 339.222 83.1431 337.53 83.1431 335.5C83.1431 333.47 84.8362 331.778 86.8681 331.778C88.8999 331.778 90.5931 333.47 90.5931 335.5Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.909 172.622C46.909 174.652 45.2158 176.344 43.184 176.344C41.1522 176.344 39.459 174.652 39.459 172.622C39.459 170.592 41.1522 168.9 43.184 168.9C45.2158 168.9 46.909 170.592 46.909 172.622Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.754 173.297C198.754 175.327 197.061 177.019 195.029 177.019C192.997 177.019 191.304 175.327 191.304 173.297C191.304 171.267 192.997 169.576 195.029 169.576C197.061 169.576 198.754 171.267 198.754 173.297Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.169 95.8858C140.169 97.9159 138.476 99.6076 136.444 99.6076C134.413 99.6076 132.719 97.9159 132.719 95.8858C132.719 93.8558 134.413 92.1641 136.444 92.1641C138.476 92.1641 140.169 93.8558 140.169 95.8858Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.901 70.578C203.901 80.6605 195.706 88.8484 185.614 88.8484C175.523 88.8484 167.328 80.6605 167.328 70.578C167.328 60.4954 175.523 52.3075 185.614 52.3075C195.706 52.2399 203.901 60.4277 203.901 70.578Z"
        fill="black"
      />
      <path
        d="M416.226 341.115C416.226 348.288 410.401 354.175 403.154 354.175C395.975 354.175 390.083 348.356 390.083 341.115C390.083 333.942 395.908 328.055 403.154 328.055C410.401 328.055 416.226 333.875 416.226 341.115Z"
        fill="black"
      />
      <path
        d="M341.455 88.848C340.1 95.5472 333.599 99.8103 326.894 98.4569C326.013 98.2539 325.2 97.9832 324.388 97.6449L328.858 131.479C328.858 131.479 327.165 141.426 312.265 143.862C297.297 146.298 287.409 139.329 289.17 127.757C289.237 127.284 290.524 120.652 291.879 113.479C291.608 113.412 291.337 113.412 290.998 113.344C274.473 109.961 263.772 93.7878 267.158 77.2768C268.58 70.4423 272.17 64.6228 277.046 60.3597C283.955 54.3372 293.572 51.4952 303.257 53.4576C314.635 55.7583 323.236 64.1491 326.42 74.5024C328.113 74.0964 330.009 74.0287 331.838 74.367C338.543 75.6527 342.877 82.1489 341.455 88.848Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.455 88.848C340.1 95.5472 333.599 99.8103 326.894 98.4569C326.013 98.2539 325.2 97.9833 324.388 97.6449L328.858 131.479C328.858 131.479 327.165 141.426 312.265 143.862C297.297 146.298 287.409 139.329 289.17 127.757C289.237 127.284 290.524 120.652 291.879 113.479C291.608 113.412 291.337 113.412 290.998 113.344C274.473 109.961 263.772 93.7878 267.158 77.2768C268.58 70.4423 272.17 64.6228 277.046 60.3597C283.955 54.3372 293.572 51.4952 303.257 53.4576C314.635 55.7583 323.236 64.1491 326.42 74.5024C328.113 74.0964 330.009 74.0287 331.838 74.367C338.543 75.6527 342.877 82.1489 341.455 88.848Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M291.878 113.411C291.878 113.411 302.173 114.832 309.623 109.622C309.623 109.622 302.444 118.554 290.863 118.554L291.878 113.411Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.878 113.411C291.878 113.411 302.173 114.832 309.623 109.622C309.623 109.622 302.444 118.554 290.863 118.554L291.878 113.411Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M326.419 74.3678C325.742 74.5031 322.627 75.3152 320.73 78.3602C318.224 82.2173 319.714 86.4804 319.918 86.8864C317.479 87.2924 314.229 88.0368 310.707 85.3977C310.029 84.8564 309.42 84.2474 308.878 83.5707L298.177 70.5784C280.5 71.3904 277.114 60.2928 277.114 60.2928C284.022 54.2703 293.639 51.4283 303.324 53.3907C314.703 55.6914 323.304 64.0146 326.419 74.3678Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M326.419 74.3678C325.742 74.5031 322.627 75.3152 320.73 78.3602C318.224 82.2173 319.714 86.4804 319.918 86.8864C317.479 87.2924 314.229 88.0368 310.707 85.3977C310.029 84.8564 309.42 84.2474 308.878 83.5707L298.177 70.5784C280.5 71.3904 277.114 60.2928 277.114 60.2928C284.022 54.2703 293.639 51.4283 303.324 53.3907C314.703 55.6914 323.304 64.0146 326.419 74.3678Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.164 141.019L29.5031 148.937C28.4872 149.41 27.2681 149.004 26.794 147.989L13.7904 120.787C13.3163 119.772 13.7226 118.554 14.7385 118.08L31.3995 110.163C37.224 107.388 44.1999 109.824 46.9767 115.644L51.6499 125.456C54.4945 131.275 51.9886 138.245 46.164 141.019Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.164 141.019L29.5031 148.937C28.4872 149.41 27.2681 149.004 26.794 147.989L13.7904 120.787C13.3163 119.772 13.7226 118.554 14.7385 118.08L31.3995 110.163C37.224 107.388 44.1999 109.824 46.9767 115.644L51.6499 125.456C54.4945 131.275 51.9886 138.245 46.164 141.019Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0412 127.555L6.06941 132.833C4.64714 133.51 2.95395 132.901 2.27668 131.48C1.59941 130.059 2.20895 128.367 3.63123 127.69L14.603 122.412L17.0412 127.555Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0412 127.555L6.06941 132.833C4.64714 133.51 2.95395 132.901 2.27668 131.48C1.59941 130.059 2.20895 128.367 3.63123 127.69L14.603 122.412L17.0412 127.555Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.049 146.366L15.0772 151.645C13.6549 152.321 11.9618 151.712 11.2845 150.291C10.6072 148.87 11.2168 147.178 12.639 146.502L23.6109 141.224L26.049 146.366Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.049 146.366L15.0772 151.645C13.6549 152.321 11.9618 151.712 11.2845 150.291C10.6072 148.87 11.2168 147.178 12.639 146.502L23.6109 141.224L26.049 146.366Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.274 273.515C236.274 273.515 216.701 311.071 184.463 307.823C147.213 304.101 116.126 199.351 111.521 182.84C111.114 181.418 111.315 179.93 112.063 178.712L136.715 135.945C137.46 134.592 139.357 134.592 140.169 135.878L199.499 228.922C199.499 228.922 207.355 200.569 228.554 166.464C249.752 132.359 297.432 128.096 297.432 128.096C297.432 128.096 334.14 122.547 372.135 139.261C407.895 155.028 426.52 176.546 446.297 225.606C452.054 239.816 463.703 269.252 447.245 286.643C433.835 300.785 398.955 302.815 378.502 302.206C351.953 301.462 331.296 293.68 319.511 288.267L340.507 244.485C364.279 243.132 388.729 245.974 412.501 246.042"
        fill="white"
      />
      <path
        d="M236.274 273.515C236.274 273.515 216.701 311.071 184.463 307.823C147.213 304.101 116.126 199.351 111.521 182.839C111.114 181.418 111.315 179.93 112.063 178.712L136.715 135.945C137.46 134.592 139.357 134.592 140.169 135.878L199.499 228.922C199.499 228.922 207.355 200.569 228.554 166.464C249.752 132.359 297.432 128.096 297.432 128.096C297.432 128.096 334.14 122.547 372.135 139.261C407.895 155.028 426.52 176.546 446.297 225.606C452.054 239.816 463.703 269.252 447.245 286.643C433.835 300.785 398.955 302.815 378.502 302.206C351.953 301.462 331.296 293.68 319.511 288.267L340.507 244.485C364.279 243.132 388.729 245.974 412.501 246.042"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M248.059 192.517L204.443 373.394C204.104 374.68 205.12 375.898 206.407 375.898L357.032 375.83C358.116 375.83 358.996 375.018 359.064 373.936L370.984 210.923L391.709 245.434"
        fill="white"
      />
      <path
        d="M248.059 192.517L204.443 373.394C204.104 374.68 205.12 375.898 206.407 375.898L357.032 375.83C358.116 375.83 358.996 375.018 359.064 373.936L370.984 210.923L391.709 245.434"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.4827 120.45C49.4827 120.45 99.804 87.2921 128.995 124.036C165.161 169.509 98.4495 258.019 118.564 310.936C138.747 363.852 168.276 368.115 159.878 393.423"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.133 397.957C159.133 399.581 157.779 400.935 156.153 400.935C154.528 400.935 153.173 399.581 153.173 397.957C153.173 396.333 154.528 394.98 156.153 394.98C157.779 394.98 159.133 396.266 159.133 397.957Z"
        fill="black"
      />
      <path
        d="M150.193 397.009L141.185 397.483"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.886 401.476L145.385 407.769"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.356 404.182L153.85 412.844"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M249.346 410.408C248.398 411.762 246.569 412.168 245.214 411.22C243.86 410.273 243.454 408.446 244.402 407.092C245.35 405.739 247.179 405.333 248.533 406.28C249.888 407.228 250.226 409.055 249.346 410.408Z"
        fill="black"
      />
      <path
        d="M242.438 404.723L234.717 400.121"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M241.354 409.392L232.482 411.016"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.589 414.13L236.681 419.882"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M296.349 157.802L289.644 125.457L268.784 131.48L296.349 157.802Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.349 157.802L289.644 125.457L268.784 131.48L296.349 157.802Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M296.349 157.802L327.842 123.495L349.65 128.976L296.349 157.802Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.349 157.802L327.842 123.495L349.65 128.976L296.349 157.802Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M290.727 119.705L289.644 125.457L296.349 157.803L327.842 123.495L326.555 113.615" fill="white" />
      <path
        d="M290.727 119.705L289.644 125.457L296.349 157.803L327.842 123.495L326.555 113.615"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M254.561 255.245L238.239 263.771C237.223 264.312 236.004 263.906 235.53 262.891L221.578 236.162C221.036 235.147 221.442 233.929 222.458 233.455L238.78 224.929C244.47 221.952 251.581 224.185 254.561 229.869L259.64 239.546C262.485 245.23 260.318 252.267 254.561 255.245Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.561 255.245L238.239 263.771C237.223 264.312 236.004 263.906 235.53 262.891L221.578 236.162C221.036 235.147 221.442 233.929 222.458 233.455L238.78 224.929C244.47 221.952 251.581 224.185 254.561 229.869L259.64 239.546C262.485 245.23 260.318 252.267 254.561 255.245Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M283.616 233.321C283.616 233.321 297.161 230.343 306.169 231.358C319.511 232.779 321.137 239.546 321.137 239.546L319.511 242.118L298.042 249.426L282.329 234.81L283.616 233.321Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M283.616 233.321C283.616 233.321 297.161 230.343 306.169 231.358C319.511 232.779 321.137 239.546 321.137 239.546L319.511 242.118L298.042 249.426L282.329 234.81L283.616 233.321Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M256.186 233.185C256.186 233.185 268.31 221.546 284.361 225.538C300.48 229.53 303.054 244.756 304.137 261.673C305.153 278.59 267.226 317.499 276.03 349.033C288.763 394.641 252.529 407.769 252.529 407.769"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M319.037 236.297C322.762 239.815 328.519 248.206 328.519 248.206L353.172 252.266L336.85 285.897L322.762 281.567C322.762 281.567 311.994 277.71 296.416 278.522C292.759 278.725 289.711 278.792 287.206 278.86C281.042 278.928 275.692 274.529 274.541 268.507C272.712 258.83 270.409 244.281 270.68 234.673C270.816 230.748 275.082 228.447 278.401 230.477C283.616 233.59 291.337 237.379 297.636 237.515C300.48 237.582 305.086 237.041 307.862 236.567"
        fill="white"
      />
      <path
        d="M319.037 236.297C322.762 239.815 328.519 248.206 328.519 248.206L353.172 252.266L336.85 285.897L322.762 281.567C322.762 281.567 311.994 277.71 296.416 278.522C292.759 278.725 289.711 278.792 287.206 278.86C281.042 278.928 275.692 274.529 274.541 268.507C272.712 258.83 270.409 244.281 270.68 234.673C270.816 230.748 275.082 228.447 278.401 230.477C283.616 233.59 291.337 237.379 297.636 237.515C300.48 237.582 305.086 237.041 307.862 236.567"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M377.486 244.755C389.203 245.296 400.919 245.973 412.433 246.041L377.486 244.755Z" fill="white" />
      <path
        d="M377.486 244.755C389.203 245.296 400.919 245.973 412.433 246.041"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M371.391 301.867C349.582 300.243 332.312 294.018 321.408 289.145C320.392 288.672 319.918 287.454 320.392 286.439L339.965 245.635C340.304 244.958 340.981 244.484 341.726 244.484C353.578 243.875 365.566 244.281 377.554 244.823"
        fill="white"
      />
      <path
        d="M371.391 301.867C349.582 300.243 332.312 294.018 321.408 289.145C320.392 288.672 319.918 287.454 320.392 286.439L339.965 245.635C340.304 244.958 340.981 244.484 341.726 244.484C353.578 243.875 365.566 244.281 377.554 244.823"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M270.612 237.449C270.612 237.449 279.417 246.516 283.616 249.358L270.612 237.449Z" fill="white" />
      <path
        d="M270.612 237.449C270.612 237.449 279.417 246.516 283.616 249.358"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M271.357 248.208C271.357 248.208 277.453 257.072 280.704 259.643L271.357 248.208Z" fill="white" />
      <path
        d="M271.357 248.208C271.357 248.208 277.453 257.072 280.704 259.643"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M273.321 262.012L280.162 270.064"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.292 235.62C319.376 236.635 320.663 238.056 321.95 239.545"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.548 139.599C132.245 132.9 127.233 124.848 117.413 122.818C100.617 119.367 109.692 94.5323 106.712 87.3594C103.8 80.1866 100.481 94.9383 100.007 102.179C99.533 109.419 99.6685 113.479 99.6685 113.479C99.6685 113.479 94.9276 107.592 97.4335 84.2467C97.4335 84.2467 98.2462 76.9385 94.5212 78.3596C90.7962 79.7806 87.8839 102.72 87.6807 107.322C87.5453 111.923 88.358 114.291 88.358 114.291C86.3262 111.923 83.2785 103.261 82.9398 98.9307C82.398 92.2316 84.4976 82.2167 83.3462 79.3069C82.6689 77.6829 80.908 78.0212 80.2307 79.1039C79.7567 79.8483 79.0794 81.8106 78.8085 82.6227C76.7767 88.3745 75.6253 95.818 75.7607 98.3894C76.0317 104.344 78.5376 116.321 78.5376 116.321C71.5617 109.013 71.6971 107.592 70.8844 97.3743C70.478 92.0962 69.1912 87.6301 67.498 85.8707C66.5498 84.8557 64.9244 85.1941 64.7212 87.6978C64.2471 92.6376 63.3667 106.036 66.6176 113.615C73.0517 128.502 71.5616 131.073 91.7444 141.156C104.68 147.584 114.094 158.749 119.242 165.855L134.548 139.599Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.548 139.599C132.245 132.9 127.233 124.848 117.413 122.818C100.617 119.367 109.692 94.5323 106.712 87.3594C103.8 80.1866 100.481 94.9383 100.007 102.179C99.533 109.419 99.6685 113.479 99.6685 113.479C99.6685 113.479 94.9276 107.592 97.4335 84.2467C97.4335 84.2467 98.2462 76.9385 94.5212 78.3596C90.7962 79.7806 87.8839 102.72 87.6807 107.322C87.5453 111.923 88.358 114.291 88.358 114.291C86.3262 111.923 83.2785 103.261 82.9398 98.9307C82.398 92.2316 84.4976 82.2167 83.3462 79.3069C82.6689 77.6829 80.908 78.0212 80.2307 79.1039C79.7567 79.8483 79.0794 81.8106 78.8085 82.6227C76.7767 88.3745 75.6253 95.818 75.7607 98.3894C76.0317 104.344 78.5376 116.321 78.5376 116.321C71.5617 109.013 71.6971 107.592 70.8844 97.3743C70.478 92.0962 69.1912 87.6301 67.498 85.8707C66.5498 84.8557 64.9244 85.1941 64.7212 87.6978C64.2471 92.6376 63.3667 106.036 66.6176 113.615C73.0517 128.502 71.5616 131.073 91.7444 141.156C104.68 147.584 114.094 158.749 119.242 165.855L134.548 139.599Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M297.094 177.02C297.094 179.05 295.4 180.741 293.369 180.741C291.337 180.741 289.644 179.05 289.644 177.02C289.644 174.99 291.337 173.298 293.369 173.298C295.4 173.298 297.094 174.99 297.094 177.02Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M297.094 177.02C297.094 179.05 295.4 180.741 293.369 180.741C291.337 180.741 289.644 179.05 289.644 177.02C289.644 174.99 291.337 173.298 293.369 173.298C295.4 173.298 297.094 174.99 297.094 177.02Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M290.389 215.05C290.389 217.08 288.696 218.772 286.664 218.772C284.632 218.772 282.939 217.08 282.939 215.05C282.939 213.02 284.632 211.329 286.664 211.329C288.696 211.329 290.389 213.02 290.389 215.05Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.389 215.05C290.389 217.08 288.696 218.772 286.664 218.772C284.632 218.772 282.939 217.08 282.939 215.05C282.939 213.02 284.632 211.329 286.664 211.329C288.696 211.329 290.389 213.02 290.389 215.05Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M277.046 293.139C277.046 295.169 275.353 296.86 273.321 296.86C271.289 296.86 269.596 295.169 269.596 293.139C269.596 291.109 271.289 289.417 273.321 289.417C275.353 289.417 277.046 291.041 277.046 293.139Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.046 293.139C277.046 295.169 275.353 296.86 273.321 296.86C271.289 296.86 269.596 295.169 269.596 293.139C269.596 291.109 271.289 289.417 273.321 289.417C275.353 289.417 277.046 291.041 277.046 293.139Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M269.19 340.169C269.19 342.199 267.497 343.89 265.465 343.89C263.433 343.89 261.74 342.199 261.74 340.169C261.74 338.138 263.433 336.447 265.465 336.447C267.497 336.447 269.19 338.071 269.19 340.169Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M269.19 340.169C269.19 342.199 267.497 343.89 265.465 343.89C263.433 343.89 261.74 342.199 261.74 340.169C261.74 338.138 263.433 336.447 265.465 336.447C267.497 336.447 269.19 338.071 269.19 340.169Z"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.236 215.998L209.454 244.148"
        stroke="black"
        strokeWidth="3.31424"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M368.546 244.417L370.984 210.921L391.709 245.432L368.546 244.417Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M368.546 244.417L370.984 210.921L391.709 245.432L368.546 244.417Z"
        stroke="black"
        strokeWidth="1.13566"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
