import {Icon, IconProps} from '@chakra-ui/react';

export function ConfirmIcon(props: IconProps) {
  return (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
      <circle cx="10" cy="10" r="10" fill="#5AC57B" />
      <path
        d="M6.22727 11.181L8.01228 13.2856L13.5201 7.42916"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
