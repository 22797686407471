import {Icon, IconProps} from '@chakra-ui/react';

export function ClockIcon(props: IconProps) {
  return (
    <Icon width="17px" height="21px" viewBox="0 0 17 21" fill="none" {...props}>
      <path
        d="M7.61539 19C11.8212 19 15.2308 15.5905 15.2308 11.3846C15.2308 7.17875 11.8212 3.76923 7.61539 3.76923C3.40952 3.76923 0 7.17875 0 11.3846C0 15.5905 3.40952 19 7.61539 19Z"
        fill="#5AC57B"
      />
      <path d="M5.53906 1H9.69291" stroke="#5AC57B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.61539 20C12.8212 20 16.2308 16.5905 16.2308 12.3846C16.2308 8.17875 12.8212 4.76923 8.61539 4.76923C4.40952 4.76923 1 8.17875 1 12.3846C1 16.5905 4.40952 20 8.61539 20Z"
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.61523 12.3846L12.042 8.95789" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.53906 2H10.6929" stroke="#101010" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
