import {Icon, IconProps} from '@chakra-ui/react';

export function AddIcon(props: IconProps) {
  return (
    <Icon width="30px" height="30px" viewBox="0 0 30 30" stroke="white" fill="#5AC57B" {...props}>
      <circle cx="15" cy="15" r="15" stroke="none" />

      <path
        d="M21.95 15.0001H8.14374M15.0469 21.9032V8.09692"
        stroke="inherit"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
