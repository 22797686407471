import {Icon, IconProps} from '@chakra-ui/react';

export function HomeIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="white" {...props}>
      <path
        d="M21 15.964C21 20.5 20 21 12 21C4.00003 21 2.99999 20 3.00003 15.964C3.00006 13.8227 3 14.3186 3 12.4444C3 10.5701 3.57474 9.50002 6.94411 6.78949C8.35369 5.65554 9.57248 4.73876 10.4601 4.09112C11.3925 3.41078 12.6264 3.38449 13.5609 4.06189C14.3853 4.65941 15.544 5.5419 17.0559 6.78949C20.5 9.6316 21 10.5 21 12.4444C20.9999 16.2045 21 12.8227 21 15.964Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 18C9.375 16.5 9 15 12 15C15 15 14.625 16.5 15 18"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
