import {Icon, IconProps} from '@chakra-ui/react';

export function RemoveIcon(props: IconProps) {
  return (
    <Icon width="30px" height="30px" viewBox="0 0 30 30" fill="none" {...props}>
      <circle cx="15" cy="15" r="15" fill="#CB2525" />
      <path
        d="M20.1656 10.1188L10.1656 20.1188M20.1656 20.1188L10.1656 10.1188"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
