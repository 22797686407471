import {Icon, IconProps} from '@chakra-ui/react';

export const LockFilledIcon = (props: IconProps) => (
  <Icon width="16px" height="20px" viewBox="0 0 16 20" fill="none" {...props}>
    <path
      d="M4.7501 5.49656C4.7501 3.92627 6.02608 2.65331 7.6001 2.65331C9.17411 2.65331 10.4501 3.92627 10.4501 5.49656V7.39206H12.3501V5.49656C12.3501 2.87944 10.2234 0.757812 7.6001 0.757812C4.97676 0.757812 2.8501 2.87944 2.8501 5.49656V7.39206H4.7501V5.49656ZM7.6001 15.9218C7.07556 15.9219 6.65023 15.4978 6.6501 14.9746C6.6501 14.9744 6.6501 14.9742 6.6501 14.9741V12.1308C6.6501 11.6074 7.07542 11.1831 7.6001 11.1831C8.12477 11.1831 8.5501 11.6074 8.5501 12.1308V14.9741C8.55023 15.4974 8.12512 15.9217 7.60059 15.9218C7.60043 15.9218 7.60026 15.9218 7.6001 15.9218Z"
      fill="#DFDFDF"
    />
    <path
      d="M12.35 7.39062H2.85C1.27598 7.39062 0 8.66358 0 10.2339V16.8681C0 18.4384 1.27598 19.7114 2.85 19.7114H12.35C13.924 19.7114 15.2 18.4384 15.2 16.8681V10.2339C15.2 8.66358 13.924 7.39062 12.35 7.39062ZM8.55001 14.9726C8.55012 15.4959 8.12504 15.9203 7.60053 15.9204C7.60036 15.9204 7.60018 15.9204 7.60001 15.9204C7.07549 15.9205 6.65012 15.4964 6.65001 14.9731C6.65001 14.973 6.65001 14.9728 6.65001 14.9726V12.1294C6.65001 11.6059 7.07531 11.1816 7.60001 11.1816C8.1247 11.1816 8.55001 11.6059 8.55001 12.1294V14.9726Z"
      fill="#ABABAB"
    />
  </Icon>
);
